import { useState } from "react";
import {Modal} from "react-bootstrap";
import { toast } from "react-toastify";

import blueLoader from "../../images/gif/blue-loader.gif";
import hourglass from "../../images/gif/hourglass.gif";


function Generate ({show, cancel, doAction, reportType, refreshData, disableAction, data, message}) {
    const [accNo, setAccNo] = useState('');

    return(
        <Modal show={show} size="md" onHide={cancel} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            {
                disableAction ?
                <div className="m-[20px] text-center">
                    <img src={hourglass} alt="" style={{width:'50px', margin:'0 auto 20px', display:'block'}} />
                    <h5 className="font-medium text-2xl">{reportType==="delete"?'Unlinking account...':'Generating report...'}</h5>
                    <p>Just a moment please</p>
                </div>
                :
                <div className="m-[20px]">
                    <h4 className="font-medium text-[1.3em]">
                        {
                            reportType==="delete"?"Unlink user account"
                            : reportType==="rerun"?"Rerun Insight"
                            :"Generate Report"
                        }
                    </h4>
                    <div className="my-[15px]">
                        <div className="myModal_main-linkBank">
                            <p>{message}</p>
                            {
                                reportType==="rerun" || reportType==="delete" ?
                                    refreshData.length>1 ? 
                                    <div className="myModal_main-linkBank-radio">
                                        <form>
                                            {
                                                refreshData.map((item, idx)=>(
                                                    <p key={idx} className="mt-[10px]">
                                                        <input type="radio" id={`test${idx}`} name="radio-group" onChange={()=>setAccNo(`${item?.accountNumber||""}`)}/>
                                                        <label for={`test${idx}`}>{item?.institution?.name||""} - {item?.accountNumber||""}</label>
                                                    </p>
                                                ))
                                            }
                                        </form>
                                    </div> 
                                    : 
                                    refreshData.length===1 ? 
                                    <div></div>
                                    :
                                    <div>
                                        <img src={blueLoader} alt="" style={{width:"50px", display:"block", margin:"0 auto 15px"}}/>
                                    </div>
                                : <div></div>
                            }
                            
                        </div>
                    </div>
                    <div className="myModal_main-widget-action">
                        <button className="cancel" onClick={cancel}>Cancel</button>
                        <button 
                            className="publish" 
                            onClick={()=>{
                                if(refreshData?.length>1){
                                    if(accNo===""){
                                        toast.error("Please select an account to continue")
                                    } else {
                                        doAction(accNo)
                                    }
                                } else {
                                    doAction(refreshData?.[0]?.accountNumber)
                                }
                            }} 
                            disabled={disableAction}
                        >
                            {
                                reportType==="rerun" || reportType==="delete" ? "Proceed":"Generate"
                            }
                        </button>
                    </div>
                </div>
            }
        </Modal>
    )
}

export default Generate;