import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {ng_states} from "../../constants/lga";
import {countries} from "../../constants/countries";
import { Modal } from "react-bootstrap";
import { AuthService } from "services/auth.service";
import { SelectField } from "components/Form/SelectField";
import { Formik, Form } from 'formik';
import { months } from "constants/months";
import { signUpSchema } from "utils/validationSchemas";
import { TextField } from "components/Form/TextField";
import { PhoneField } from "components/Form/PhoneField";
import Button from "components/shared/Button";

import passwordGood from "images/svg/passwordGood.svg";
import passwordBad from "images/svg/passwordBad.svg";
import ngFlag from "images/countries/nigeria.svg";
import ghFlag from "images/countries/ghana.svg";
import saFlag from "images/countries/southafrica.svg";
import keFlag from "images/countries/kenya.svg";
import rwFlag from "images/countries/rwanda.svg";
import tzFlag from "images/countries/tanzania.svg";
import { handleRequestErrors } from "utils/helpers";


function SignUp () {
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [states, setStates] = useState([]);
    const [show, setShow] = useState(false);
    const history = useNavigate()
  
    const handleClose = () => setShow(false);

    const handleDataFetch = (name, id, item) => {
        if(name==="states"){
            if (id==="160"){
                setStates(ng_states);
            } else {
                const data = []
                AuthService.fetchStates(id)
                    .then((response) => response.data)
                    .then((resp) => {
                        if(resp.success){
                            for ( let i in resp.data) data.push(resp.data[i])
                            setStates(data)
                        } else {
                        }
                    })
                    .catch((err) => {
                    });
            }
        }
    }

    const submitHandler = (values) => {
        let payload = JSON.stringify(values);
        const acceptedMou = document.getElementById('acceptedMou');
        const readTerms = document.getElementById('readTerms');

        if (readTerms.checked) {
            if (acceptedMou.checked) {
                setSignUpLoading(true);
                AuthService.SignupAction(payload)
                    .then((response) => response.data)
                    .then((res) => {
                        setSignUpLoading(false);
                        if (res.status === "success") {
                            sessionStorage.setItem('otp', res?.data?.otp)
                            toast.success("Account created successfully!!!");
                            history(`/auth/verification/email-verify/${values.email}/${res?.data?._doc?.businessName}/${res?.data?._doc?._id}/${res?.data?._doc?.userId}`)
                        } else {
                            toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        setSignUpLoading(false);
                        handleRequestErrors(err)
                    });
            } else {
                toast.info("Please make sure that you accept our MOU terms to proceed!")
            }
        } else {
            toast.info("Please make sure that you have read our terms and conditions first!")
        }
    }

    const initialValues ={
        businessType: '',
        businessName: '',
        businessSector: '',
        country: "",
        state: "",
        lga: "",
        address: "",
        email: "",
        phoneNumber: "",
        password: ""
    }

    const availableCountries = [
        {
            name: "Nigeria",
            icon: ngFlag
        },
        {
            name: "Kenya",
            icon: keFlag
        },
        {
            name: "Ghana",
            icon: ghFlag
        },
        {
            name: "South Africa",
            icon: saFlag
        },
        {
            name: "Rwanda",
            icon: rwFlag
        },
        {
            name: "Tanzania",
            icon: tzFlag
        },
    ]

    const formatedCountries = countries
        .filter(item => availableCountries.some(x => x.name === item.name))
        .map(item => {
            const match = availableCountries.find(x => x.name === item.name);
            return {
                ...item,
                icon: match.icon,
            };
        });

    return(
        <div className="mt-[50px]">
            <Formik
                initialValues={initialValues}
                validationSchema={signUpSchema}
                onSubmit={(values) => {
                    submitHandler(values)
                }}
            >  
                {(props) => (
                    <Form>
                        <SelectField
                            name='businessType'
                            options={[
                                { value: 'FINTECH', label: 'FINTECH' },
                                { value: 'MFI', label: 'MFI' },
                                { value: 'MFB', label: 'MFB' },
                                { value: 'SACCOs', label: 'SACCOs' },
                                { value: 'Retail', label: 'Retail' },
                                { value: 'eCommerce', label: 'eCommerce' },
                                { value: 'Others', label: 'Others' },
                            ]}
                            label="Business Type"
                            placeholder="Business Type"
                            onChange={(selected)=>{
                                props.setFieldValue( "businessType", selected.label )
                            }}
                            value={props?.values?.businessType}
                        />
                        <TextField
                            name='businessName'
                            type='text'
                            placeholder="Business Name"
                            label="Business Name"
                        />
                        <SelectField
                            name='businessSector'
                            options={[
                                { value: 'Finance', label: 'Finance' },
                                { value: 'Telecom', label: 'Telecom' },
                                { value: 'Energy', label: 'Energy' },
                                { value: 'Retail', label: 'Retail' },
                                { value: 'Shops', label: 'Shops' },
                                { value: 'Travel', label: 'Travel' },
                                { value: 'Restaurant', label: 'Restaurant' },
                                { value: 'Insurance', label: 'Insurance' },
                                { value: 'Automobile', label: 'Automobile' },
                                { value: 'Water', label: 'Water' },
                                { value: 'Leisure', label: 'Leisure' },
                                { value: 'Pension', label: 'Pension' },
                                { value: 'Health', label: 'Health' },
                                { value: 'Services', label: 'Services' },
                                { value: 'Legal', label: 'Legal' },
                                { value: 'Government Services', label: 'Government Services' },
                                { value: 'Properties', label: 'Properties' },
                            ]}
                            label="Business Sector"
                            placeholder="Business Sector"
                            onChange={(selected)=>{
                                props.setFieldValue( "businessSector", selected.label )
                            }}
                            value={props?.values?.businessSector}
                        />
                        <SelectField
                            name='country'
                            options={formatedCountries?.map(item => ({
                                value: item.name,
                                label: <div className="flex items-center gap-1.5"><img src={item.icon} alt={item.name} />{item.name}</div>,
                                id: item.id,
                                visible: false,
                            }))}
                            label="Country"
                            searchable={true}
                            placeholder="Country"
                            onChange={(selected)=>{
                                props.setFieldValue( "country", selected.value )
                                handleDataFetch("states", selected.id)
                            }}
                            value={props?.values?.country}
                        />
                        <SelectField
                            name='state'
                            options={states?.map(item => ({
                                value: item.name,
                                label: item.name,
                            }))}
                            label="State"
                            searchable={true}
                            placeholder="State"
                            onChange={(selected)=>{
                                props.setFieldValue( "state", selected.value )
                            }}
                            value={props?.values?.state}
                        />
                        {
                            props.values.country==="Nigeria" &&
                            <TextField
                                name='lga'
                                type='text'
                                placeholder="LGA"
                                label="Local Government Adress"
                            />
                        }
                        <TextField
                            name='address'
                            type='text'
                            placeholder="Address"
                            label="Address"
                        />
                        <TextField
                            name='email'
                            type='text'
                            placeholder="e.g example@companyname.com"
                            label="Company Email Address"
                        />
                        <PhoneField
                            name='phoneNumber'
                            label="Phone Number"
                            onChange={(status, phoneNumber, country)=>{
                                props.setFieldValue( "phoneNumber", phoneNumber )
                            }}
                        />
                        <TextField
                            name='password'
                            type='password'
                            placeholder="Password"
                            label="Password"
                        />
                        {
                            props?.values?.password!=="" &&
                            <ul className="auth_signup-passwordCheck">
                                <li> 
                                    <img src={props?.values?.password?.length>= 8 ? passwordGood : passwordBad} alt="icon" />
                                    <span>Must be at least 8 characters</span>
                                </li>
                                <li> 
                                    <img src={/[a-z]/.test(props?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                    <span>Must contain at least 1 lower case letters(s)</span>
                                </li>
                                <li> 
                                    <img src={/[A-Z]/.test(props?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                    <span>Must contain at least 1 upper case letters(s)</span>
                                </li>
                                <li> 
                                    <img src={/\d/.test(props?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                    <span>Must contain at least 1 numbers</span>
                                </li>
                                <li> 
                                    <img src={/\W/.test(props?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                    <span>Must contain at least 1 special character</span>
                                </li>
                            </ul>
                        }
                    
                        <div style={{display:"flex", alignItems:"flex-start", marginTop:"20px"}}>
                            <input type="checkbox" style={{width:"45px", margin:"6px 7px 0 0"}} id="readTerms" />
                            <p>I have read, understood and i agree to Creditchek's <a style={{color:'#0046E6', cursor:'pointer', textDecoration:"none"}} href="https://creditchek.africa/terms-of-use" target="_blank" rel="noreferrer"> Terms and Conditions </a> and that my information will only be used according to CreditChek’s <a style={{color:'#0046E6', cursor:'pointer', textDecoration:"none"}} href="https://creditchek.africa/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy </a></p>
                        </div>
                        <div style={{display:"flex", alignItems:"flex-start",  margin:"10px 0 10px"}}>
                            <input type="checkbox" style={{width:"25px", margin:"6px 7px 0 0"}} id="acceptedMou" />
                            <p>I have read, understood and i agree to Creditchek’s <span style={{color:'#0046E6', cursor:'pointer'}}  onClick={() => setShow(true)}  >Memorandum of Understanding</span></p>
                        </div>
                        <Button
                            isValid={props.isValid}
                            isLoading={signUpLoading}
                            type="submit"
                            text="Open Account"
                            className="mb-[30px] mt-2.5"
                        />
                        <p className="text-cc-black-5">Already registered? <Link to="/auth/login"><span className="cursor-pointer text-cc-primary font-semibold !no-underline">Login</span></Link></p>
                        <p style={{color:"#8A8A8A", marginBottom:"50px !important"}} >&copy; CreditChek  Technologies</p>
                        
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    MEMORANDUM OF UNDERSTANDING BETWEEN [CREDITCHEK TECHNOLOGIES NIGERIA LIMITED] AND [{props?.values?.businessName}]
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>This memorandum of understanding (this <strong>“Agreement”</strong>) is made on this {new Date().getDate()}
                                    {
                                        new Date().getDate()===1 || new Date().getDate()===11 || new Date().getDate()===21 || new Date().getDate()===31 ? "st" :
                                        new Date().getDate()===2 || new Date().getDate()===12 || new Date().getDate()===22 ? "nd" :
                                        new Date().getDate()===3 || new Date().getDate()===13 || new Date().getDate()===23 ? "rd" :
                                        "th"
                                    }{" "} 
                                    day of {months[new Date().getMonth()]} {new Date().getFullYear()}. </p>
                                <p>BETWEEN</p>
                                <p><strong>Creditchek Technologies Nigeria Limited</strong> a company incorporated under the laws of Federal Republic of Nigeria whose business address is Plot 24 Adebisi Ogunniyi Crescent, Lekki Phase 1, Lagos State (hereinafter referred to as <strong>“[Creditchek]”</strong>) which expression shall where the context so admits include its successors-in-title and permitted assigns, of the first part; </p>
                                <p><strong>AND</strong></p>
                                <p><strong>{props?.values?.businessName}</strong> a company incorporated under the laws of Federal Republic of Nigeria whose registered business address is {props?.values?.address} (hereinafter referred to as “) which expression shall where the context so admits include its successors-in-title and permitted assigns, of the second part. </p>
                                <p>Creditchek and Moni may be referred to in the plural as the <strong>"Parties"</strong> and in the singular as a <strong>“Party”</strong> to this Agreement.</p>
                                <p><strong>WHEREAS</strong></p>
                                <ol type="A">
                                    <li>Creditchek is a data infrastructure platform that provides Identity and credit assessment to Government and Corporate Organizations across various sectors.</li>
                                    <li>Creditchek aggregates data from credit bureaus, financial institutions and other real time sources to provide Identity and credit assessment solution to Clients.</li>
                                    <li>The Partner, {props?.values?.businessName} which is a company {props?.values?.businessType}</li>
                                    <li>The partner is desirous of using Creditchek’s services for Credit and Identity assessment.</li>
                                    <li>The Parties deem it expedient to enter into this Agreement in order to provide for the terms and conditions for the provision of the Services and certain matters related thereto by entering into this agreement.</li>
                                </ol>
                                <p>The Parties wish to explore and engage in a potential business relationship and investment opportunity (the <strong>“Purpose”</strong>) wherein certain Confidential Information will be divulged in the course of discussion and evaluation.</p>
                                <p><strong>NOW THEREFORE</strong>, in consideration of the mutual covenants, terms and conditions set forth herein, the Parties agree as follows:</p>
                                <ol type="1">
                                    <strong><li>CONFIDENTIAL INFORMATION</li></strong>
                                        <div>
                                            <p>
                                                1.1 For purposes of this Agreement, “Disclosing Party” means a party, including its Affiliates, whose
                                                employees, agents, representatives or consultants have disclosed the Confidential Information, and
                                                “Receiving Party” means the party, including its Affiliates, whose employees, agents, representatives
                                                or consultants have received the Confidential Information.
                                            </p>
                                            <p>
                                                1.2 “Affiliates” means any company or legal entity that is directly or indirectly controlling, controlled by, or
                                                under common control with, a Party (where “control” means the ownership of more than fifty percent
                                                (50%) of the stock or other equity interests entitled to vote for the election of directors or an equivalent
                                                governing body. All references to a party herein shall be deemed to include its Affiliates.
                                            </p>
                                            <p>
                                                1.3 “Confidential Information” means non-public information that a party designates as being proprietary or
                                                confidential or which by its nature or the circumstances surrounding its disclosure reasonably ought to
                                                be treated as confidential. Confidential Information includes, without limitation and in whatever format,
                                                information relating to the Disclosing Party’s software products, source code, API data files,
                                                documentation, specifications, databases, networks, system design, file layouts, tool combinations,
                                                development methods, consulting methodologies, user manuals and documentation, training materials
                                                (computer-based or otherwise), schema, flow charts, prototypes and evaluation copies of any of the
                                                foregoing, as well as information relating to the Disclosing Party’s business or financial affairs, which
                                                may include business methods, marketing strategies, pricing, competitor information, product
                                                development strategies and methods, customer lists and customer information and financial results.
                                            </p>
                                            <p>1.4 Confidential Information shall not include any information that:
                                                <ol type="i">
                                                    <li>is known by the Receiving Party free of any obligation to keep it confidential;</li>
                                                    <li>is at the time of disclosure, or thereafter becomes, publicly available through no wrongful act of the Receiving Party;</li>
                                                    <li>is independently developed by the Receiving Party without relying on or referring to the Confidential Information of the Disclosing Party; or</li>
                                                    <li>is approved for release by prior written authorization of the Disclosing Party.</li>
                                                </ol>
                                            </p>
                                        </div>
                                    <strong><li>TERM AND TERMINATION</li></strong>
                                        <div>
                                            <p>
                                                2.1 Subject at all times to clause 2.1.4, this Agreement shall commence on the date of the Party
                                                signing last in time and shall remain in force until the earliest of:
                                            </p>
                                            <div style={{marginLeft:"20px"}}>
                                                <p>2.1.1 the date upon which the Parties agree that this Agreement has been superseded by any further written agreement(s) entered into between them;</p>
                                                <p>2.1.2 the date upon which the Parties agree in writing to discontinue the Purpose; or</p>
                                                <p>2.1.3 the date of expiry of thirty (30) days&#39; written notice of termination given by one Party to the other.</p>
                                                <p>
                                                    2.1.4 Notwithstanding the expiry or termination for whatever reason of this Agreement, the
                                                    obligations of confidentiality contained in this Agreement shall, unless otherwise agreed,
                                                    continue for a period of three (3) years in respect of Confidential Information disclosed under
                                                    this Agreement from the date of disclosure of the relevant Confidential Information or, if
                                                    later, the date of termination of this Agreement. The parties agree that all obligations herein
                                                    with respect to Confidential Information of the other party received pursuant to this
                                                    Agreement shall survive and continue, indefinitely, after any expiration or termination of this
                                                    Agreement.
                                                </p>
                                                <p>
                                                    2.1.5 Upon the Disclosing Party’s request, or upon expiration or termination of this Agreement, the
                                                    Receiving Party will promptly return or destroy all originals, copies and summaries of the
                                                    Confidential Information. If requested by the Disclosing Party, the Receiving Party shall
                                                    certify in writing that all such Confidential Information has been returned or destroyed.
                                                </p>
                                            </div>
                                        </div>
                                        <strong><li>CONFIDENTIALITY OBLIGATIONS</li></strong>
                                        <div>
                                            <p>3.1 In respect of any and all Confidential Information obtained, received, accessed or learned by
                                            the Receiving Party from the Disclosing Party, the Receiving Party undertakes to the
                                            Disclosing Party that it shall:</p>
                                                                        <div style={{marginLeft:"20px"}}>
                                                                            <p>3.1.1 keep the Confidential Information secret;</p>
                                                                            <p>3.1.2 use the Confidential Information only for the Purpose and not for its own benefit or the
                                            benefit of any third party;</p>
                                            <p>3.1.3 keep the Confidential Information in a safe and secure place and use reasonable
                                            technical and organizational security measures to prevent and avoid, theft, unauthorized
                                            access, dissemination or unauthorized use, destruction or loss; including, at a minimum,
                                            those measures it takes to protect its own Confidential Information;</p>
                                            <p>3.1.4 at all times, including after termination of this Agreement, not directly or indirectly disclose
                                            the Confidential Information (or allow it to be disclosed), in whole or in part, to any person
                                            or third party unless: (i) the Receiving Party has obtained written permission from the
                                            Disclosing Party; and (ii) the third party has signed a non-disclosure agreement
                                            containing terms that are no less stringent than those imposed in this Agreement;</p>
                                            <p>3.1.5 not take any Copies of the whole or any part of the Confidential Information save as is
                                            strictly necessary for the Purpose and all such Copies shall be deemed to be Confidential
                                            Information;</p>
                                            <p>3.1.6 inform the Disclosing Party immediately on becoming aware, or suspecting, that any
                                            person who is not an Authorized Person has become aware of Confidential Information;
                                            and</p>
                                            <p>3.1.7 upon written request from the Disclosing Party immediately return to the Disclosing Party
                                            and destroy and permanently erase all Confidential Information and Copies which are in
                                            the Receiving Party&#39;s or any Authorized Persons’ possession, custody or control and
                                            provide the Disclosing Party with a certificate signed by a duly authorized representative
                                            of the Receiving Party confirming that the provisions of this clause 3.1.7 have been fully
                                            complied with.</p>
                                        </div>
                                    </div>
                                <strong><li>DISCLOSURE AND USE</li></strong>
                                    <div>
                                        <p>
                                            4.1 The Receiving Party and its Affiliates may disclose the Confidential Information only to those
                                            of their employees, agents, representatives or consultants with a need-to-know to
                                            accomplish the Purpose. The Receiving Party shall advise such persons of their obligations
                                            under this Agreement with respect to Confidential Information and ensure that they are bound
                                            by non-disclosure obligations no less stringent than those imposed in this Agreement. The
                                            Receiving Party shall be liable to the Disclosing Party for any improper disclosure or use of
                                            Confidential Information made by such persons and its Affiliates to the same extent as if the
                                            Receiving Party itself had made such improper disclosure or use.
                                        </p>
                                        <p>4.2 The Receiving Party shall:</p>
                                            <div style={{marginLeft:"20px"}}>
                                                <p>
                                                    4.2.1 use all reasonable measures to avoid disclosure, dissemination or unauthorized use of
                                                    Confidential Information, including, at a minimum, those measures it takes to protect its
                                                    own Confidential Information;
                                                </p>
                                                <p>
                                                    4.2.2 at all times, including after termination of this Agreement, not disclose any Confidential
                                                    Information of the Disclosing Party to any third party unless: (i) the Receiving Party has
                                                    obtained written permission from the Disclosing Party; and (ii) the third party has signed
                                                    a non-disclosure agreement containing terms that are no less stringent than those
                                                    imposed in this Agreement;
                                                </p>
                                                <p>4.2.3 use the Confidential Information only as needed for the Purpose;</p>
                                                <p>4.2.4 not disclose, reproduce or use Confidential Information except as expressly permitted by this Agreement; and</p>
                                                <p>
                                                    4.2.5 not disassemble, de-compile, reverse engineer or otherwise attempt to recreate the
                                                    Confidential Information without the Disclosing Party’s consent in writing, nor assist or
                                                    allow a third party to do any of the foregoing.
                                                </p>
                                            </div>
                                        <p>4.3 The Parties agree not to directly or indirectly export or transmit any Confidential Information in violation of restrictions or rules imposed by Nigeria or any other relevant country.</p>
                                    </div>
                                <strong><li>DISCLOSURE AND USE</li></strong>
                                    <div>
                                        <p>
                                            5.1 Nothing contained in this Agreement shall be construed as granting or conferring rights by
                                            license or otherwise in any Confidential Information. The Disclosing Party owns and retains
                                            sole and exclusive right, title and interest to the Confidential Information it discloses, and any
                                            product developed thereof. The Receiving Party shall notify the Disclosing Party immediately
                                            upon discovery of any unauthorized use or disclosure of Confidential Information, or any
                                            other breach of this Agreement by it, its Affiliates, or their employees, agents or consultants,
                                            and shall cooperate in every reasonable way to help the Disclosing Party re-gain possession
                                            of its Confidential Information and prevent further unauthorized disclosure or use thereof.
                                        </p>
                                        <p>5.2 All Confidential Information is provided “AS IS” and without warranty, whether expressed or implied, as to its accuracy or completeness.</p>
                                    </div>
                                <strong><li>DOCUMENT REQUESTS IN LEGAL PROCEEDINGS</li></strong>
                                    <div>
                                        <p>
                                            If a Receiving Party is requested or required, pursuant to a legal or administrative process, to disclose
                                            any Confidential Information supplied by the Disclosing Party, the Receiving Party will, to the extent
                                            permitted by law, use all reasonable efforts to promptly notify the Disclosing Party so that the
                                            Disclosing Party may seek any appropriate protective order and/or other available remedies to
                                            prevent or limit disclosure. If in the absence of a protective order or receipt of a waiver hereunder,
                                            and in the judgment of its counsel the Receiving Party is compelled to disclose Disclosing Party’s
                                            Confidential Information, the Receiving Party shall use all reasonable efforts, to the extent allowed by
                                            law, to inform the Disclosing Party as far as practicable in advance of such disclosure.
                                        </p>
                                    </div>
                                <strong><li>REMEDIES</li></strong>
                                    <div>
                                        <p>
                                            The Parties agree that the breach of any term, covenant or provision of this Agreement may cause
                                            irreparable harm to the other Party and, accordingly, upon the breach by a Party of any term or
                                            provision of this Agreement, the other Party shall be entitled to seek injunctive relief in addition to any
                                            other remedy available at law or in equity. If either Party employs attorneys to enforce any rights
                                            arising out of this Agreement, the prevailing Party shall be entitled to recover reasonable attorney
                                            fees in addition to any other costs and damages to which it may be entitled.
                                        </p>
                                    </div>
                                <strong><li>FEES &amp; REVENUE SHARE</li></strong>
                                    <div>
                                        <p>
                                            Creditchek will provide an initial wallet funding of <strong>N2000 FREE</strong> to enable the partner run some live
                                            tests before deciding to further fund their business wallet.
                                        </p>
                                    </div>
                                <strong><li>MISCELLANEOUS</li></strong>
                                    <div>
                                        <p>
                                            9.1 <strong style={{textDecoration:"underline"}}>No obligation to proceed:</strong> Nothing herein shall obligate any Party to proceed with any
                                            transaction or disclose any Confidential Information, and each Party reserves the right to
                                            terminate the discussions contemplated by this Agreement at any time.
                                        </p>
                                        <p>
                                            9.2 <strong style={{textDecoration:"underline"}}>No assignment:</strong> The Agreement may not be assigned by a party to any third Party unless
                                            the other Party agrees to such assignment in writing. The Agreement shall benefit and be
                                            binding upon the Parties hereto and their successors.
                                        </p>
                                        <p>
                                            9.3 <strong style={{textDecoration:"underline"}}>Entire agreement:</strong> This Agreement constitutes the entire understanding between the Parties
                                            with respect to the subject matter hereof and shall not be altered, modified or amended
                                            except in writing executed by both Parties. No waiver of any provision of this Agreement
                                            shall constitute a waiver of any other provision(s) or of the same provision on another
                                            occasion.
                                        </p>
                                        <p>
                                            9.4 <strong style={{textDecoration:"underline"}}>Severability:</strong> If any provision of this Agreement is held to be invalid or unenforceable for any
                                            reason, the remaining provisions will continue in full force without being impaired or
                                            invalidated in any way. The parties agree to replace any invalid provision with a valid
                                            provision which most closely approximates the intent and economic effect of the invalid
                                            provision.
                                        </p>
                                        <p>
                                            9.5 <strong style={{textDecoration:"underline"}}>Governing law/legal venue:</strong> This Agreement shall be governed by the laws of Nigeria and
                                            the stipulations set forth herein to be construed in accordance with same. The Parties hereby
                                            agree that, in the event of any dispute between the Parties relating to this Agreement, the
                                            Parties shall first seek to resolve the dispute amicably through informal discussions. In the
                                            event any dispute cannot be resolved informally within 10 (ten) days of initializing
                                            discussions, the dispute or difference shall be escalated to the level of the Managing Officers
                                            of both institutions for resolution. If the CEOs are unable to resolve the dispute or difference
                                            by mutual agreement within 30 (thirty) days of first discussions, either of the Parties may refer
                                            the matter for resolution by arbitration in accordance with the Arbitration and Conciliation Act,
                                            Cap A18 Laws of the Federation of Nigeria, 2004 and in accordance with this Clause, by
                                            serving a 10 (Ten) days’ notice (an “Arbitration Notice”) on the other Party. The proceedings
                                            shall be held in Lagos State and shall be conducted in English language. Nothing contained
                                            in this Clause shall prevent either Party from obtaining interim or urgent relief from a Court of
                                            competent jurisdiction.
                                        </p>
                                        <p>
                                            9.6 <strong style={{textDecoration:"underline"}}>Counterparts:</strong> This Agreement may be executed in multiple counterparts (e.g. by scanned
                                            PDF or other agreed electronic format), each of which shall be deemed an original and all of
                                            which taken together shall constitute one and the same Agreement.
                                        </p>
                                        <p>
                                            9.7 <strong style={{textDecoration:"underline"}}>Indemnity:</strong> The Receiving Party agrees to indemnify and hold the Disclosing Party harmless
                                            from and against all losses, claims, damages and expenses, which result directly from its
                                            breach of the confidentiality obligations under this Agreement or unauthorized use or
                                            disclosure of the Confidential Information by it, its representatives or employees.
                                        </p>
                                    </div>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SignUp;