import axios from "axios";


export const OtherService = {
    getGeolocation: () => {
        return axios.get('https://geolocation-db.com/json/', {
            // withCredentials: true,  this causes CORS error
            // json: true,
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'x-www-form-urlencoded',
                // 'Access-Control-Allow-Origin' : '*'
            }
        })
    },
}