import { SelectField } from "components/Form/SelectField";
import { Formik, Form } from 'formik';
import Button from "components/shared/Button";
import { useSelector, useDispatch } from "react-redux";
import { AuthService } from "services/auth.service";
import { logToBin } from "../../lib/logs";
import { toast } from "react-toastify";
import { initial } from "redux/login";
import { setActiveApp, setApps } from "redux/apps";
import {useNavigate} from "react-router-dom";

import tick from "images/svg/float/tick.svg";
import logo from "images/svg/logo.svg";
import { useState } from "react";
import { resendVerificationCode } from "lib/doVerification";
import { setBusinessData } from "redux/business";
import { useDeviceInfo } from "hooks/device.hook";
import { useGeolocation } from "hooks/geolocation.hook";


export default function ChooseProfile () {
    let history = useNavigate();
    const dispatch = useDispatch();
    const tenant = useSelector(state => state?.loginData?.multiTenant)
    const [loading, setLoading] = useState(false);
    const deviceInfo = useDeviceInfo()
    const geolocation = useGeolocation()

    const doSubmit = (userId) => {
        let payload = JSON.stringify({
            userId,
            email: tenant?.email?.toLowerCase(),
            password: tenant.password,
        });
        AuthService.ContinueLogin(payload, geolocation, deviceInfo)
            .then((response) => response.data)
            .then((res) => {
                setLoading(false)
                toast.success(`Login successful!`);
                if(res?.data?.isActive==="pending") { // if multitenant account selected still has a pending approval
                    sessionStorage.setItem(
                        "b2b_details",
                        JSON.stringify({
                            bussinessName: res.data.businessName,
                            bussinessId: res.data.businessId
                        })
                    );
                    dispatch(initial(res.data));
                    dispatch(setApps(res.data.apps));
                    dispatch(setActiveApp(res.data.apps[0]))
                    sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.isActive }));
                    dispatch(setBusinessData({isComplied: res.data.isComplied}))
                    setTimeout( function (){
                        history('/compliance');
                    }, 1500);
                } else {
                    routeToDashboard(res)
                }
            })
            .catch((err) => {
                setLoading(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    if(err.response.data.message === 'please verify your email'){
                        setTimeout( function (){
                            resendVerificationCode(tenant?.email?.toLowerCase(), err.response.data.data.businessId, err.response.data.data.businessName, err.response.data.data.userId)
                        }, 1500);
                        toast.info('Please verify your email')
                    } else if(err.response.data.message === "Verify this login"){
                        toast.info('Please verify your login attempt')
                        sessionStorage.setItem('password', tenant.password)
                        sessionStorage.setItem('otp', err?.response?.data?.data?.otp)
                        history(`/auth/verification/login-verify/${tenant?.email?.toLowerCase()}/${err?.response?.data?.data?.businessName}/${err?.response?.data?.data?.businessId}/userId`)
                    } else {
                        toast.error(err.response.data.message);
                    }
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const routeToDashboard = (res) => {
        dispatch(initial(res.data))
        res?.data?.user?.roleId && sessionStorage.setItem("roleId", JSON.stringify(res?.data?.user?.roleId))
        
        sessionStorage.setItem( "webhook", res.data.webhookUrl);

        sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.user.isActive })); 
        dispatch(setBusinessData({isComplied: res.data.isComplied}))

        dispatch(setApps(res.data.apps));
        dispatch(setActiveApp(res.data.apps[0]))
        
        history('/');
    }

    return(
        <div>
            <h4 className="text-[23px] font-bold mt-10">Multiple Business Tenant Profile</h4>
            <div className="relative md:flex md:justify-start md:mt-5 md:-mb-[50px] mt-[100px]">
                <div className='md:hidden h-1 -z-[3] w-[calc(100%-0px)] absolute top-[15px] mx-[0px] rounded-[4px] bg-cc-blue-5'>
                    <div className={`bg-cc-blue-4 h-1 rounded-[4px] w-full`}></div>
                </div>
                <div className="flex justify-between items-center mx-[0px] md:mx-0 md:block">
                    <div 
                        className={`md:mb-[40px] md:mr-[10px] w-[30px] h-[30px] shrink-0 rounded-full border-4 bg-cc-primary border-cc-blue-3`}
                    >
                        <img src={tick} alt="tick" className="block mx-auto mt-1" />
                    </div>
                    <div 
                            className={`md:mb-[40px] md:mr-[10px] w-[30px] h-[30px] shrink-0 rounded-full border-4 bg-cc-primary border-cc-blue-3`}
                    >
                        <img src={tick} alt="tick" className="block mx-auto mt-1" />
                    </div>
                    <div className={`md:mb-[40px] md:mr-[10px] w-[30px] h-[30px] shrink-0 rounded-full border-4 bg-cc-primary border-cc-blue-5'}`}>
                        <p className={`text-center text-white font-semibold -mt-[1px]`}>3</p>
                    </div>
                </div>
                <div className="flex justify-between md:block -mx-10">
                    <div className="text-center md:w-full md:text-left h-[70px]">
                        <h5 className={`text-cc-primary font-semibold mt-1.5 text-[1em]`}>Authenticate</h5>
                    </div>
                    <div className="text-center md:w-full md:text-left h-[70px]">
                        <h5 className={`text-cc-primary font-semibold mt-1.5 text-[1em]`}>Select Tenant</h5>
                    </div>
                    <div className="text-center md:w-full md:text-left h-[70px]">
                        <h5 className={`text-cc-primary font-semibold mt-1.5 text-[1em]`}>Access Business</h5>
                    </div>
                </div>
            </div>
            <div className="shadow-lg p-[20px]">
                <img src={logo} alt="logo" className="w-[150px] block mx-auto " />
                <h4 className="font-bold text-cc-grey-21 text-lg text-center mt-3">Authenticated Businesses</h4>
                <Formik
                    initialValues={{
                        tenant: ''
                    }}
                    onSubmit={(values) => {
                        setLoading(true)
                        doSubmit(values.tenant)
                    }}
                >  
                    {(props) => (
                        <Form>
                            <div className="pt-5 px-3">
                                <SelectField
                                    name='tenant'
                                    options={tenant?.accounts?.map(item => ({
                                        value: item.userId,
                                        label: item.businessName
                                    }))}
                                    label="Select Tenant"
                                    placeholder="Select Tenant"
                                    onChange={(selected)=>{
                                        props.setFieldValue( "tenant", selected.value )
                                    }}
                                    value={props?.values?.tenant}
                                />
                                <Button
                                    isValid={props.isValid}
                                    isLoading={loading}
                                    type="submit"
                                    text="Proceed"
                                    className="mb-[30px] mt-2.5"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
} 