import creditcheklogo from "images/logos/logo-notext.svg";
import { motion } from "framer-motion";


export default function PageLoader () {
    return (
        <div className="w-[100vw] h-[100vh] fixed flex items-center justify-center bg-white">
            <motion.img 
                src={creditcheklogo} 
                alt="logo" 
                className="w-[90px]"
                animate={{ scale: [1, 1.3, 1] }}
                transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }} 
            />
        </div>
    )
}