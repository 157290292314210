import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setFeedbackSource, setShow } from "redux/feedback";


export const useBilledPageExit = (source) => {
    // this hook opens the feedback modal for customers
    const location = useLocation();
    const dispatch = useDispatch();
    const triggerFeedbackModal = useSelector(state => state?.feedbackData?.triggerModal);

    useEffect(() => {
        return () => {
            if(triggerFeedbackModal){
                dispatch(setFeedbackSource(source))
                dispatch(setShow(true))
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, triggerFeedbackModal, source]);
};

export const useFeedbackModal = () => {
    const dispatch = useDispatch();
    const triggerFeedbackModal = useSelector(state => state?.feedbackData?.triggerModal);
    
    const showFeedback = useCallback((source) => {
        if(triggerFeedbackModal){
            dispatch(setFeedbackSource(source))
            dispatch(setShow(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { showFeedback }
};
