import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import ShareYourData from "../components/ShareYourData";

import identity from "images/svg/identity/identity.svg";
import credit from "images/svg/identity/credit.svg";
import linked from "images/svg/identity/linked.svg";
import logo from "images/svg/logo.svg";
import creditcheklogo from "images/svg/home/creditchek.svg";
import { handleRequestErrors } from "utils/helpers";
import { IncomeService } from "services/income.service";
// import { IncomeService } from "services/income.service";


export default function LinkAccIndex () {
    let history = useNavigate()
    const [fetched, setFetched] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState(null)
    const [searchParams] = useSearchParams();
    const d_param = searchParams.get("d")

    let isDev = process.env.REACT_APP_NODE_ENV === 'development'
    let baseURL = isDev ? 
        process.env.REACT_APP_Dev_Cluster
        :
        process.env.REACT_APP_Production_Cluster

    useEffect(()=>{
        if(!!d_param){
            IncomeService.decryptText({text: d_param})
                .then(resp => {
                    const dd = JSON.parse(resp)
                    setData(dd)
                })
                .catch(()=>{})
        }
    },[d_param])

    useEffect(()=>{
        if(!!data?.appLink){
            axios.get(`${baseURL}identity/widget/show-form-data?appLink=${data?.appLink}`)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === true) {
                        setFetched(res.data);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.appLink])

    const handleAction = () => {
        axios.post(`${baseURL}identity/borrower/userconsent`, {
            borrowerId: data?.borrowerId,
            appId: data?.app_id,
            action: "bank account linking"
        })
            .then((response) => response.data)
            .then(() => {
                setShowModal(true);
            })
            .catch((err) => {
                handleRequestErrors(err)
            })
    }

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    return(
        <div className="max-w-[500px] block mx-auto bg-white border border-cc-primary rounded-[10px]">
            <div className="m-[20px]">
                <div className="flex items-center justify-between">
                    <p className="text-[30px] leading-[35px] mr-[20px] font-bold">Link your bank account data for {fetched.businessName}</p>
                    <div className="w-[70px] h-[70px] shrink-0 overflow-hidden border-[0.3px] border-blue-400 rounded-full block my-2.5 mx-auto">
                        <img src={fetched?.logo?.length?fetched.logo:creditcheklogo} alt="upload" width="100%" />
                    </div>
                </div>
                <div style={{margin:"20px 0"}}>
                    <p className="my-3">I consent to provide {fetched.businessName} with information about my identity, bank account transaction, credit history and telecommunication for the purpose of performing credit worthiness assessments within the CreditChek infrastructure, and improve my chances to access better financial services.</p>
                    <div className="flex items-center mb-2"><img src={identity} alt="" style={{marginRight:"7px"}}/><p>Identity Information</p></div>
                    <div className="flex items-center mb-2"><img src={credit} alt="" style={{marginRight:"7px"}}/><p>Credit Bureau History</p></div>
                    <div className="flex items-center mb-2"><img src={linked} alt="" style={{marginRight:"7px"}}/><p>Linked Bank Accounts</p></div>
                    <p>By clicking the button below you consent to Creditchek’s platform Use agreement</p>
                </div>
                <div className="myModal_main-widget-action">
                    <button className="cancel" onClick={handleClose}>No, I do not consent</button>
                    <button className="publish" onClick={()=>handleAction()}>Yes, I consent</button>
                </div>
                <div className="my-[20px] flex justify-center">
                    <p className="text-[1em] text-center font-bold mr-2">Powered by </p><img width="90px" src={logo} alt="logo"/>
                </div>
            </div>

            <ShareYourData
                data={fetched} 
                show={showModal} 
                handleAction={(option)=>{
                    option==="upload-pdf" ?
                        history(`/customer/share-data/upload-pdf`)
                        : 
                        history(`/customer/share-data/open-banking`)
                }}
            />

        </div>
    )
}