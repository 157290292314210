import { useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import { resendVerificationCode } from "../../lib/doVerification";
import { AuthService } from "services/auth.service";
import { TextField } from "components/Form/TextField";
import { Formik, Form } from 'formik';
import { logToBin } from "../../lib/logs";
import { Mixpanel } from '../../lib/mixpanel';
import { loginSchema } from "utils/validationSchemas";
import Button from "components/shared/Button";
import { useDispatch } from "react-redux";
import { initial, multiTenant } from "redux/login";
import { setActiveApp, setApps } from "redux/apps";
import { setBusinessData } from "redux/business";
import { useDeviceInfo } from "hooks/device.hook";
import { useGeolocation } from "hooks/geolocation.hook";
import { handleRequestErrors } from "utils/helpers";


function Login () {
    let history = useNavigate();
    const dispatch = useDispatch();
    const [loginLoading, setLoginLoading] = useState(false);
    const deviceInfo = useDeviceInfo()
    const geolocation = useGeolocation()

    const doLogin = (values) => {
        setLoginLoading(true)
        let payload = JSON.stringify({
            email: values?.email?.toLowerCase(),
            password: values?.password,
        });
        
        AuthService.LoginAction(payload, geolocation, deviceInfo)
            .then((response) => response.data)
            .then((res) => {
                setLoginLoading(false)
                if (res.status === true) {
                    if(res?.data?.multipleAccount) {
                        history('/auth/choose-profile');
                        dispatch(multiTenant({
                            email: values?.email,
                            password: values?.password,
                            accounts: res.data.accounts,
                        }))
                    } else {
                        toast.success(`Login successful! Redirecting to dashboard...`);
                        sessionStorage.setItem(
                            "b2b_details",
                            JSON.stringify({
                                bussinessName: res.data.businessName,
                                bussinessId: res.data.businessId
                            })
                        );
                        const res_data = res.data
                        const is_complied = res_data?.isActive==="rejected" ? false : res_data?.isComplied
                        res_data.isComplied = is_complied
                        
                        dispatch(initial(res_data));
                        dispatch(setApps(res_data?.apps));
                        dispatch(setActiveApp(res_data?.apps[0]))
                        sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res_data?.isActive }));
                        dispatch(
                            setBusinessData({
                                isComplied: is_complied
                            })
                        )
                        setTimeout( function (){
                            is_complied ? history('/'):history('/compliance');
                        }, 1500);
                    }
                } else if (res.status === "success") {
                    //mixpanel
                    Mixpanel.identify(res.data.user.id,);
                    Mixpanel.track('Login button was clicked', {
                        bussinessName: res.data.businessName,
                        email: res.data.user.email,
                        role: res.data.user.role,
                    });
                    Mixpanel.people.set({
                      $first_name: res.data.user.firstName,
                      $last_name: res.data.user.lastName,
                      $email: res.data.user.email
                    });
                    routeToDashboard(res)
                } else {
                    toast.error(res.message);
                }
            })
            .catch((err) => {
                setLoginLoading(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    if(err.response.data.message === 'please verify your email'){
                        setTimeout( function (){
                            resendVerificationCode(values.email, err.response.data.data.businessId, err.response.data.data.businessName, err.response.data.data.userId)
                        }, 1500);
                        toast.info('Please verify your email')
                    } else if(err.response.data.message === "Verify this login"){
                        toast.info('Please verify your login attempt')
                        sessionStorage.setItem('password', values.password)
                        sessionStorage.setItem('otp', err?.response?.data?.data?.otp)
                        history(`/auth/verification/login-verify/${values.email}/${err.response.data.data.businessName}/${err.response.data.data.businessId}/${err.response.data.data.userId}`)
                    } else {
                        toast.error(err.response.data.message);
                    }
                } else{
                    handleRequestErrors(err, true)
                }
            });
    }

    const routeToDashboard = (res) => {
        toast.success(`Login successful!`);
        dispatch(initial(res.data))
        res?.data?.user?.roleId && sessionStorage.setItem("roleId", JSON.stringify(res?.data?.user?.roleId))
        
        sessionStorage.setItem( "webhook", res.data.webhookUrl);

        sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.user.isActive })); 
        dispatch(setBusinessData({isComplied: res.data.isComplied}))

        dispatch(setApps(res.data.apps));
        dispatch(setActiveApp(res.data.apps[0]))
        
        history('/');
    }

    const initialValues ={
        email: '',
        password: '',
    }

    return(
        <div className="mt-[80px]">
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={(values) => {
                    doLogin(values)
                }}
            >  
                {(props) => (
                    <Form>
                        <TextField
                            name='email'
                            type='text'
                            placeholder="Email Address"
                        />
                        <TextField
                            name='password'
                            type='password'
                            placeholder="Password"
                        />
                        <Button
                            isValid={props.isValid}
                            isLoading={loginLoading}
                            type="submit"
                            text="Login"
                            className="mb-[30px] mt-2.5"
                        />
                    </Form>
                )}
            </Formik>
            <p className="text-cc-black-5 mb-1">Don't have an account? <Link to="/auth/signup"><span className="text-cc-primary">Create an account</span></Link></p>
            <p className="text-cc-black-5">Forgot your password? <Link to="/auth/forgot-password"><span className="text-cc-primary">Reset Password</span></Link></p>
        </div>
    )
}

export default Login;