import tableIcon from "images/svg/loan-performance.svg";
import { useEffect, useState } from "react";
import Chart from "../Chart";
import toGraph from "images/svg/income/toGraph.svg";
import toTable from "images/svg/income/toTable.svg";
import { currencyFormatter } from "utils/helpers";
import clsx from "clsx";


export default function RecurrentExpenses () {
    const [isGraph, setIsGraph] = useState(false);
    const [edtiData, setEdtiData] = useState({
        tableFor: "edti",
        headings: ["Month", "Amount"],
        items: []
    })
    const [tableData, setTableData] = useState([])

    let incomeData = null;

    try {
        let details = sessionStorage?.getItem('incomeInsightData');
        incomeData = JSON?.parse(details);
    } catch (err) {
        //err
    }

    useEffect(()=>{
        try{
            let recurrent = incomeData?.edti?.recurrent_expenses_debt;
            const availableMonths = Object.keys(recurrent)?.reverse() ?? [];

            let result = Object.keys(recurrent).map((key) => [key, recurrent[key]])
            const data = availableMonths.map((item)=>({
                month: item ?? "---",
                amount: recurrent[item] ?? 0,
            }))
            setTableData(data)
            
            setEdtiData({
                ...edtiData,
                items: result,
                headings: ["Month", "Debt Burden ratio (amount)"]
            })
        } catch {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="shadow-[0px_4px_8px_0px_#1018281A] p-[20px]">
            <div className="mb-6 border-[1px] border-[#D0D5DD] flex items-center gap-1.5 p-2.5 rounded">
                <img src={tableIcon} alt="icon" />
                <p className="text-[18px] font-medium">Monthly Recurrent Expenses (MRE)</p>
            </div>
            <div className="flex items-center mt-6">
                {
                    isGraph ?
                    <div>
                        <Chart data={edtiData.items} type={"recurrent"}/>
                    </div>
                    :
                    <div className="w-full">
                        <div className="box-border capitalize border-[1px] border-[#B9B9B9] rounded-[8px] 2xl:overflow-x-scroll">
                            <table className="w-[100%]">
                                <thead>
                                    <tr>
                                        {
                                                ["Month", 
                                                    // "Expense Category", 
                                                    "Average Monthly Amount", 
                                                    // "Frequency", "Percentage of Income"
                                                    ].map((item,idx)=>(
                                                <th 
                                                    key={idx}
                                                    className={clsx(
                                                        "bg-[#7E7E7E1A] border-b-[1px] border-[#B9B9B9] font-semibold text-black opacity-[100] p-2.5",
                                                        idx!==0 && "border-l-[1px]"
                                                    )}
                                                >
                                                    {item}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.map((item, idx)=>(
                                            <tr key={idx}>
                                                {
                                                    [item.month, 
                                                        // "---", 
                                                        currencyFormatter(item.amount/100, 2, "NGN"), 
                                                        // "---", "---"
                                                        ].map(x => (
                                                        <td 
                                                            key={x}
                                                            className={clsx(
                                                                "p-2.5",
                                                                idx+1!==tableData.length && "border-b-[1px]",
                                                                x!==item.month && "border-l-[1px]"
                                                            )}
                                                        >{x}</td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <img className="income_detailsNav-table-switch" src={isGraph?toTable:toGraph} alt="switch tab" onClick={()=>setIsGraph(!isGraph)} />
            </div>
        </div>
    )
}