import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { BankService } from "services/bank.service";
import { IncomeService } from "services/income.service";
import { IdentityService } from "services/identity.service";
import { useNavigate } from "react-router-dom";
import uploadComplete from 'images/svg/upload-complete.svg';
import cc_logo from "images/logos/white-logo.svg";
import { FormikProvider, useFormik } from "formik";
import { SelectField } from 'components/Form/SelectField';
// import clsx from 'clsx';
import { TextField } from 'components/Form/TextField';
import { DragToUpload } from 'components/Form/DragToUpload';
import { pdfUploadFormSchema } from 'utils/validationSchemas';
import { handleRequestErrors } from 'utils/helpers';
import Button from 'components/shared/Button';
import { RecovaService } from 'services/recova.service';
import { MdArrowDropDown } from "react-icons/md";


function PdfForm () {
    let history = useNavigate();
    const [successful, setSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ngBanks, setNgBanks] = useState([]);
    const [microDepositBanks, setMicroDepositBanks] = useState([]);
    const customerData = JSON.parse(sessionStorage.getItem("customerData"))

    useEffect(()=>{
        BankService.getAllBankLogo()
            .then(resp=>{
                const banksToRemove = [
                    "023", // Citibank
                    // "50211", // Kuda
                    "102", // Titan Trust
                ]
                const filteredBanks = resp.data.filter(item => !banksToRemove.includes(item.code))
                setNgBanks(filteredBanks);
            })
        RecovaService.getMicrodepositBanklist()
            .then((response) => response.data)
            .then((res) => {
                if(res.status===true){
                    setMicroDepositBanks(res?.data ?? [])
                }
            })
            .catch((err) => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const doUpload = (values) => {
        setIsLoading(true)

        // change some slugs to what backend expects
        let newslug = values.accountType==='corporate' ? (
                values.bank.slug==="access-bank"?"accessbank"
                : values.bank.slug==="fidelity-bank"?"fidelitybank"
                : values.bank.slug==="first-bank-of-nigeria"?"fbn"
                : values.bank.slug==="first-city-monument-bank"?"fcmb2"
                : values.bank.slug==="providus-bank"?"providusbank"
                : values.bank.slug==="standard-chartered-bank"?"scb"
                : values.bank.slug==="union-bank-of-nigeria"?"unionbank"
                : values.bank.slug==="zenith-bank"?"zenithbank"
                : values.bank.slug
            )
            :
            (
                values.bank.slug==="access-bank"?"accessbank"
                : values.bank.slug==="fidelity-bank"?"fidelitybank"
                : values.bank.slug==="first-bank-of-nigeria"?"fbn"
                : values.bank.slug==="first-city-monument-bank"?"fcmb"
                : values.bank.slug==="guaranty-trust-bank"?"gtbank"
                : values.bank.slug==="stanbic-ibtc-bank"?"stanbicibtc"
                : values.bank.slug==="sterling-bank"?"sterlingbank"
                : values.bank.slug==="united-bank-for-africa"?"uba"
                : values.bank.slug==="zenith-bank"?"zenithbank"
                : values.bank.slug 
            )
    
        let newOption = (
                values.bank.slug==="palmpay" ||
                values.bank.slug==="paga" ||
                values.bank.slug==="kuda-bank" ||
                values.bank.slug==="paycom"
            ) ? "mono" : values.accountType

        let data = new FormData();
        data.append('borrowerId', customerData?.borrowerId);
        data.append('bankCode', values.bank.code);
        data.append('appId', customerData?.app_id);
        data.append('accountNumber', values.accountNumber);
        data.append('accountName', values.accountName);
        data.append('accountType', newOption);
        if (values.password!=="") {
            data.append('password', values.password);
        }
        if(customerData?.type==='long'){
            data.append('bankName', values.bank.name);
        }else{
            data.append('bankName', newslug);
        }
        data.append('statement', values.file);

        if(customerData?.type==='long'){
            IdentityService.uploadBorrowerStatement(data)
                .then((response) => response.data)
                .then((res) => {
                    setIsLoading(false)
                    if(res.status===true){
                        setSuccessful(true)
                        toast.success(res.message)
                        form.resetForm()
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    handleRequestErrors(err, false, 60000)
                });
        } else {
            IncomeService.uploadBankStatement(data)
                .then((response) => response.data)
                .then((res) => {
                    setIsLoading(false)
                    if(res.status==="success"){
                        setSuccessful(true)
                        toast.success(res.message)
                        form.resetForm()
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    handleRequestErrors(err, false, 60000)
                });
        }
    }

    const doBack = () => {
        if(successful){
            handleClose()
        } else {
            history(-1)
        }
    }

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    const form = useFormik({
        initialValues: {
            accountType: "savings",
            bank: "",
            accountNumber: "",
            accountName: "",
            password: "",
            file: "",
        },
        validationSchema: pdfUploadFormSchema,
        enableReinitialize: false,
        onSubmit: (values) => {
            doUpload(values)
        }
    })

    const bankNipCode = microDepositBanks?.find(x => x?.bank_code === form.values?.bank.code)?.nip_code

    useEffect(()=>{
        if(!!form.values.bank && form.values.accountNumber.length===10 && !!bankNipCode){
            let payload = JSON.stringify({
                bankCode: bankNipCode,
                accountNumber: form.values.accountNumber,
            })
            RecovaService.directDebitNameEnquiry(payload)
                .then(response=> response.data)
                .then(res => {
                    if (typeof res.data === "string"){
                        form.setFieldValue("accountName", res.data);
                    } else {
                        toast.error("Bank name enquiry currently not available, please contact support.")
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        } else {
            form.setFieldValue("accountName", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.accountNumber])

    return(
        <div className="max-w-[500px] block mx-auto bg-cc-primary text-white bg-[url('./images/svg/pattern/lines.svg')] bg-no-repeat bg-cover border border-cc-primary rounded-[18px]" >
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit} className="m-[20px]" autoComplete='off'>
                    {
                        !successful ?
                        <div>
                            <img src={cc_logo} alt="creditchek-logo" className='w-[200px]'/>
                            <h4 className='font-dm_sans font-bold text-[27px] sm:text-[20px] mt-[20px]'>Upload Bank Statement</h4>
                            <p className='font-medium text-base sm:text-xs mb-6'>Upload and attach your bank account statement.</p>
                            {/* <div className='flex justify-between my-[20px]'>
                                {
                                    [
                                        {label: 'Individual Account', value: "savings"},
                                        {label: 'Corporate Account', value: "corporate"},
                                        {label: 'Mono PDF', value: "mono"},
                                    ].map((item, idx)=>(
                                        <p
                                            key={idx}
                                            className={clsx(
                                                'font-medium cursor-pointer text-sm px-[20px] py-2.5',
                                                form.values.accountType===item.value && "border-b-[2px] border-b-white",
                                            )}
                                            onClick={()=>{
                                                form.setFieldValue("accountType", item.value)
                                            }}
                                        >
                                            {item.label}
                                        </p>
                                    ))
                                }
                            </div> */}
                            <div className='box-border'>
                                <div className='text-black relative -my-3 -mx-3'>
                                    <SelectField
                                        name='accountType'
                                        options={
                                            [
                                                {label: 'Individual Account', value: "savings"},
                                                {label: 'Corporate Account', value: "corporate"},
                                                // {label: 'Mono PDF', value: "mono"},
                                            ].map((item) => ({
                                                label: item.label,
                                                value: item.value
                                            }))
                                        }
                                        onChange={(selected)=>{
                                            form.setFieldValue( "accountType", selected.value )
                                        }}
                                        value={form?.values?.accountType}
                                        backgroundColor="transparent"
                                        inputValueTextColor="white"
                                        typingTextColor="white"
                                        hideOnlyLine={true}
                                        transparentBorder={true}
                                        customDropdown={
                                            <div className='mr-[12px]'>
                                                <MdArrowDropDown className='text-white w-6 scale-[1.8]' />
                                            </div>
                                        }
                                    />
                                    {/* <MdArrowDropDown className='absolute text-white top-2.5 right-2.5 w-6 scale-[2] -z-[0]' /> */}
                                </div>
                                <div className='text-black'>
                                    <SelectField
                                        name='bank'
                                        searchable
                                        options={ngBanks.map((item) => ({
                                            label: (
                                                <div className="flex items-center">
                                                    <img src={item?.logo?.url} alt="" className="w-5 h-5 mr-[5px] bg-white"/>
                                                    <p className="m-0">{item?.name}</p>
                                                </div>
                                            ),
                                            value: item.name,
                                            obj: item
                                        }))}
                                        labelColor="font-medium text-sm text-white"
                                        label="Choose bank"
                                        placeholder={
                                            <div className='flex items-center gap-1.5'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_23425_54502)">
                                                        <path d="M9.16699 1.66406C13.307 1.66406 16.667 5.02406 16.667 9.16406C16.667 13.3041 13.307 16.6641 9.16699 16.6641C5.02699 16.6641 1.66699 13.3041 1.66699 9.16406C1.66699 5.02406 5.02699 1.66406 9.16699 1.66406ZM9.16699 14.9974C12.3895 14.9974 15.0003 12.3866 15.0003 9.16406C15.0003 5.94073 12.3895 3.33073 9.16699 3.33073C5.94366 3.33073 3.33366 5.94073 3.33366 9.16406C3.33366 12.3866 5.94366 14.9974 9.16699 14.9974ZM16.2378 15.0566L18.5953 17.4132L17.4162 18.5924L15.0595 16.2349L16.2378 15.0566Z" fill="#12B76A"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_23425_54502">
                                                            <rect width="20" height="20" fill="white" transform="translate(0 -0.00390625)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className='text-white'>Search for your bank</span>
                                            </div>
                                        }
                                        onChange={(selected)=>{
                                            form.setFieldValue( "bank", selected.obj )
                                        }}
                                        value={form?.values?.bank}
                                        backgroundColor="transparent"
                                        inputValueTextColor="white"
                                        typingTextColor="white"
                                        hideArrowAndLine={true}
                                    />
                                </div>
                                <TextField
                                    name='accountNumber'
                                    type='text'
                                    placeholder="Account Number"
                                    label="Enter Account Number"
                                    labelColor="font-medium text-sm text-white"
                                />
                                <TextField
                                    name='accountName'
                                    type='text'
                                    placeholder={!!bankNipCode ? "Account Name":"Enter your account name"}
                                    label="Enter Account Name"
                                    labelColor="font-medium text-sm text-white"
                                    readOnly={!!bankNipCode}
                                />
                                <TextField
                                    name='password'
                                    type='password'
                                    placeholder="Enter password"
                                    label="PDF Password(if available)"
                                    labelColor="font-medium text-sm text-white"
                                />
                                <div className='max-w-[340px] mx-auto block'>
                                    <DragToUpload
                                        name="file"
                                        fileType="pdf"
                                        message={<span>PDF files ONLY &#40;max. 10MB&#41;</span>}
                                        onChange={(file)=>form.setFieldValue("file", file)}
                                        clickTextColor="text-white"
                                        contentTextColor="text-[#12B76A]"
                                    />
                                    <p className='-mt-2.5'>{form?.values?.file?.name||""}</p>
                                </div>
                                <div className='grid grid-cols-2 gap-6 mt-[20px]'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Go back"
                                        className="bg-[transparent] border-[1px] border-white"
                                        onClick={doBack} 
                                    />
                                    <Button
                                        isValid={true}
                                        isLoading={isLoading}
                                        type="submit"
                                        text="Upload Statement"
                                        className="bg-white !text-cc-primary border-[1px] border-white"
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="myModal_main-widget-shareLeft" >
                            <img src={uploadComplete} alt="icon" />
                            <h4>{"Statement Upload Succesful"}</h4>
                            {/* <p>{"Would you like to upload another bank statement"}</p> */}
                            <div className='grid grid-cols-1 gap-6 mt-[20px]'>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Close Page"
                                    className="bg-[transparent] border-[1px] border-white"
                                    onClick={doBack} 
                                />
                                {/* <Button
                                    isValid={true}
                                    isLoading={isLoading}
                                    type="button"
                                    text="Add Another Statement"
                                    className="bg-white !text-cc-primary border-[1px] border-white"
                                    onClick={()=>setSuccessful(false)} 
                                /> */}
                            </div>
                        </div>
                    }
                    {/* <div className="myModal_main-widget-share-action">
                        <button className="goBack" type='button' onClick={doBack} style={{width:"39%"}}>{successful?"Close Page":"Go Back"}</button>
                        <button className="share" type='submit' style={{marginLeft:"auto", width:"39%"}} disable={isLoading}>{isLoading?<img src={loader} alt="" width="25px" className="block mx-auto" />:successful?'Add Another Statement':"Upload Statement"}</button>
                    </div> */}
                </form>
            </FormikProvider>
        </div>
    )
}

export default PdfForm;