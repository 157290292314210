import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import OTPInput from "otp-input-react";
import { resendVerificationCode } from "../../lib/doVerification";
import { toast } from "react-toastify";
import { AuthService } from "services/auth.service";
import { useDispatch } from "react-redux";
import { initial } from "redux/login";
import { setActiveApp, setApps } from "redux/apps";

import otpHide from "../../images/svg/otp-hide.svg";
import otpShow from "../../images/svg/otp-view.svg";
import { setBusinessData } from "redux/business";
import { useDeviceInfo } from "hooks/device.hook";
import { useGeolocation } from "hooks/geolocation.hook";
import { handleRequestErrors } from "utils/helpers";
import logo from "images/svg/logo.svg";


function Otp (props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [OTP, setOTP] = useState("");
    const [secureOtp, setSecureOtp] = useState(true);
    const [ minutes, setMinutes ] = useState(5);
    const [seconds, setSeconds ] =  useState(0);
    const params = useParams();
    const deviceInfo = useDeviceInfo()
    const geolocation = useGeolocation()
    const codeprefix = sessionStorage?.getItem("otp")?.split("-")?.[0]

    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    const toggleOtpShow = () => {
        setSecureOtp(!secureOtp);
    }

    const sendOtp = (e) => {
        e.preventDefault();
        if(params.type==='email-verify'){
            emailVerify()
        } else {
            loginVerify()
        }
    }

    const emailVerify = () => {
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        let payload = JSON.stringify({
            otp: `${codeprefix}${OTP}`,
            businessName : params.bussName,
            email : params.email.toLowerCase()
            // 
        })
        AuthService.verifyAction(payload)
            .then((response) => response.data)
            .then((res) => {
                btn.innerHTML=`Continue`;
                if (res.status === "success") {
                    toast.success("User successfully verified, redirecting to login...");
                    setTimeout( function (){
                        history(`/auth/login`);
                    }, 1500);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                btn.innerHTML=`Continue`;
                handleRequestErrors(err)
            });
    }

    const loginVerify = () => {
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        let payload = JSON.stringify({
            email: params.email.toLowerCase(),
            password: sessionStorage.getItem('password'),
            token: `${codeprefix}${OTP}`,
        })
        AuthService.verifyLoginAction(payload, geolocation, deviceInfo)
            .then((response) => response.data)
            .then((res) => {
                btn.innerHTML=`Continue`;
                if (res.status === "success") {
                    sessionStorage.removeItem("password");
                    toast.success(`Login successful!`);
                    dispatch(initial(res.data))
                    res?.data?.user?.roleId && sessionStorage.setItem("roleId", JSON.stringify(res?.data?.user?.roleId))
                    sessionStorage.setItem( "webhook", res.data.webhookUrl);

                    sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.user.isActive })); 
                    dispatch(setBusinessData({isComplied: res.data.isComplied}))
                    
                    dispatch(setApps(res.data.apps));
                    dispatch(setActiveApp(res.data.apps[0]))
                    
                    history('/')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                btn.innerHTML=`Continue`;
                handleRequestErrors(err)
            });
    }

    const resendCode = () => {
        resendVerificationCode(params.email, params.id, params.userId)
    }

    const maskEmail = () => {
        const initial_split = params?.email?.split("@")
        const before_at = initial_split[0] ?? ""
        const after_at = initial_split[1] ?? ""
        let maskedString;
        if (before_at.length < 6) {
            maskedString = '*'.repeat(before_at.length);
        } else {
            maskedString = before_at.slice(0, -6) + '*'.repeat(5) + before_at.slice(-1);
        }
        return maskedString+"@"+after_at
    }

    return(
        <div>
            <form>
                <div>
                    <img src={logo} alt="logo" className="w-[200px] mt-[70px] mb-[30px] sm:my-[30px]" />
                    <h4 className="font-bold text-[#0D0D0D] text-2xl mb-3">Enter Your OTP Code</h4>
                    <p>Enter the remaining 6 characters OTP sent to your email {maskEmail()}</p>
                </div>
                <div className="auth_verify flex items-center box-border">
                    <p className="text-2xl font-semibold whitespace-nowrap mr-2.5">{codeprefix} - </p>
                    <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={secureOtp} />
                    <img src={secureOtp ? otpHide : otpShow} onClick={toggleOtpShow} alt="otp" className="w-[20px] ml-auto cursor-pointer" />
                </div>
                <button 
                    className={ OTP.length===6 ?"auth_signup-active":"auth_signup-submit"} 
                    onClick={sendOtp}
                    disabled={OTP.length !== 6}
                    id="actionBtn"
                >Continue</button>
                <p className="auth_verify-text1">Didn’t get code? {" "}
                    { minutes === 0 && seconds === 0
                        ? <span onClick={resendCode}>Resend code</span>
                        : <>Resend code in <span> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> </>
                    }
                </p>
            </form>
        </div>
    )
}

export default Otp;