import clsx from "clsx";
import tableIcon from "images/svg/loan-performance.svg";
import { useEffect, useState } from "react";
import Chart from "../Chart";
import toGraph from "images/svg/income/toGraph.svg";
import toTable from "images/svg/income/toTable.svg";
import { currencyFormatter } from "utils/helpers";


export default function MonthlySittingBalance () {
    const [balanceFilter, setBalanceFilter] = useState('year');
    const [isGraph, setIsGraph] = useState(false);
    const [edtiData, setEdtiData] = useState({
        tableFor: "edti",
        headings: ["Month", "Amount"],
        items: []
    })
    const [tableData, setTableData] = useState([])

    let incomeData = null;

    try {
        let details = sessionStorage?.getItem('incomeInsightData');
        incomeData = JSON?.parse(details);
    } catch (err) {
        //err
    }

    const convertObject = (key) => {
        const breakdown = incomeData?.edti?.creditBreakdown
        const objKeys = Object.keys(breakdown[`${key}`]) ?? [];
        const objVals = Object.values(breakdown[`${key}`]) ?? [];
        let result = {
            months: objKeys,
            monthRange: objKeys.length>1 ? `${objKeys[0]} - ${objKeys.at(-1)}`:(objKeys[0]||"---"), 
            amountSum: objVals?.reduce((acc, num) => acc + num, 0) ?? 0
        };
        if(objKeys.length>0){
            return result;
        } else {
            return null
        }
    }

    useEffect(()=>{
        try{
            let balance = incomeData?.edti?.balance;
            const availableMonths = Object.keys(balance)?.reverse() ?? [];

            if (balanceFilter==="quarter") {
                const data = []
                const dataKeys = [
                    { debit: "debitFirstQuarter0", credit: "creditFirstQuarter0"},
                    { debit: "debitSecondQuarter0", credit: "creditSecondQuarter0"},
                    { debit: "debitThirdQuarter0", credit: "creditThirdQuarter0"},
                    { debit: "debitFourthQuarter0", credit: "creditFourthQuarter0"},
                    { debit: "debitFirstQuarter1", credit: "creditFirstQuarter1"},
                    { debit: "debitSecondQuarter1", credit: "creditSecondQuarter1"},
                    { debit: "debitThirdQuarter1", credit: "creditThirdQuarter1"},
                    { debit: "debitFourthQuarter1", credit: "creditFourthQuarter1"},
                ]
                dataKeys.forEach(item=>{
                    if(!!convertObject(item.credit)?.months?.length>0) {
                        data.push({
                            month: convertObject(item.credit)?.monthRange ?? "---",
                            balance: balance[convertObject(item.credit)?.months.at(-1) ?? ""] ?? 0,
                            debit: convertObject(item.debit)?.amountSum  ?? 0,
                            credit: convertObject(item.credit)?.amountSum ?? 0 ,
                        })
                    }
                })
                setTableData(data)
            } else if (balanceFilter==="bi-annual") {
                const data = []
                const dataKeys = [
                    { debit: "debitFirstHalf0", credit: "creditFirstHalf0"},
                    { debit: "debitSecondHalf0", credit: "creditSecondHalf0"},
                    { debit: "debitFirstHalf1", credit: "creditFirstHalf1"},
                    { debit: "debitSecondHalf1", credit: "creditSecondHalf1"},
                ]
                dataKeys.forEach(item=>{
                    if(!!convertObject(item.credit)?.months?.length>0) {
                        data.push({
                            month: convertObject(item.credit)?.monthRange ?? "---",
                            balance: balance[convertObject(item.credit)?.months.at(-1) ?? ""] ?? 0,
                            debit: convertObject(item.debit)?.amountSum  ?? 0,
                            credit: convertObject(item.credit)?.amountSum ?? 0 ,
                        })
                    }
                })
                setTableData(data)
            } else {
                let result = Object.keys(balance).map((key) => [key, balance[key]])
                const data = availableMonths.map((item)=>({
                    month: item ?? "---",
                    balance: balance[item] ?? 0,
                    debit: incomeData?.edti?.creditBreakdown?.monthlyDebit?.[item] ?? 0,
                    credit: incomeData?.edti?.creditBreakdown?.monthlyCredit?.[item] ?? 0,
                }))
                setTableData(data)
                setEdtiData({
                    ...edtiData,
                    items: result.reverse(),
                    headings: incomeData?.edti?.creditBreakdown?.monthlyCredit ? ["Month", "End-of-month balance", "Debit", "Credit"] : ["Month", "End-of-month balance"]
                })
            }
        } catch(e) {}
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[balanceFilter])

    return (
        <div className="shadow-[0px_4px_8px_0px_#1018281A] p-[20px]">
            <div className="mb-6 border-[1px] border-[#D0D5DD] flex items-center gap-1.5 p-2.5 rounded">
                <img src={tableIcon} alt="icon" />
                <p className="text-[18px] font-medium">Monthly Sitting Balance</p>
            </div>
            <div className="flex items-center gap-2.5">
                <p className="text-[#4C8BF5]">Filter By</p>
                {
                    [
                        {label: "1 year", value: "year"},
                        {label: "Bi-Annually", value: "bi-annual"},
                        {label: "Quarterly", value: "quarter"},
                    ].map(item => (
                        <p 
                            className={clsx(
                                "rounded-[5px] py-[5px] text-center border-[1px] border-[#888888] text-[#888888] w-[100px] cursor-pointer",
                                balanceFilter===item.value && "border-[#0046E6] bg-[#0046E6] text-white"
                            )}
                            onClick={()=>setBalanceFilter(item.value)}
                        >{item.label}</p>
                    ))
                }
            </div>
            <div className="flex items-center mt-6">
                {
                    isGraph ?
                    <Chart data={edtiData.items} type={"sitting"}/>
                    :
                    <div className="w-[100%]">
                        <div className="box-border w-[100%] capitalize border-[1px] border-[#B9B9B9] rounded-[8px] 2xl:overflow-x-scroll">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        { 
                                            ["Month", "End-of-month balance", "Debit", "Credit"].map((item,idx)=>(
                                                <th 
                                                    key={idx}
                                                    className={clsx(
                                                        "bg-[#7E7E7E1A] border-b-[1px] border-[#B9B9B9] font-semibold text-black opacity-[100] p-2.5",
                                                        idx!==0 && "border-l-[1px]"
                                                    )}
                                                >
                                                    {item}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.map((item, idx)=>(
                                            <tr key={idx}>
                                                {
                                                    [item.month, currencyFormatter(item.balance/100, 2, "NGN"), currencyFormatter(item.debit/100, 2, "NGN"), currencyFormatter(item.credit/100, 2, "NGN")].map(x => (
                                                        <td 
                                                            key={x}
                                                            className={clsx(
                                                                "p-2.5",
                                                                idx+1!==tableData.length && "border-b-[1px]",
                                                                x!==item.month && "border-l-[1px]"
                                                            )}
                                                        >{x}</td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <img className="income_detailsNav-table-switch" src={isGraph?toTable:toGraph} alt="switch tab" onClick={()=>setIsGraph(!isGraph)} />
            </div>
        </div>
    )
}