import {Routes, Route, Navigate, useSearchParams, useLocation} from "react-router-dom";

import CustomerKYC from "./CustomerKYC";
import PdfForm from "./LinkAccount/PdfForm";
import LinkAccIndex from "./LinkAccount";
import EDocSuccess from "./EDocSuccess";
import DirectDebit from "./direct_debit";
import OpenBanking from "./LinkAccount/OpenBanking";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { IncomeService } from "services/income.service";
import dayjs from "dayjs";
import PageLoader from "components/PageLoader";
import { FcExpired } from "react-icons/fc";


export default function Customers () {
    const [searchParams] = useSearchParams();
    const d_param = searchParams.get("d")
    const [loading, setLoading] = useState(false);
    const [expired, setExpired] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Link expired!")
    const location = useLocation();
    const isFirstPages = (
        location.pathname==="/customer/share-data" ||
        location.pathname==="/customer/onboarding"
    )

    useEffect(()=>{
        if(!!d_param){
            setLoading(true)
            IncomeService.decryptText({text: d_param})
                .then(resp => {
                    const dd = JSON.parse(resp)
                    sessionStorage.setItem("customerData", JSON.stringify(dd))
                    setLoading(false)
                    const isValidLink = dayjs().isBefore(dayjs(dd.expires))
                    setExpired(!isValidLink)
                })
                .catch(()=>{
                    setLoading(false)
                    setExpired(true)
                    setErrorMessage("Link is invalid!")
                })
        } else if (!d_param && isFirstPages) {
            setExpired(true)
            setErrorMessage("Link is invalid!")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[d_param])

    return(
        <div 
            className="bg-cc-blue-11 min-h-[100vh]">
            <Helmet>
                <title>CreditChek: Link your Bank Account</title>
            </Helmet>
            {
                loading ? 
                <PageLoader />
                :
                expired ? 
                <div className="flex flex-col items-center justify-center text-center min-h-[100vh] px-10">
                    <FcExpired className="scale-[5] mb-10" />
                    <p className="text-xl font-semibold">{errorMessage} Please contact the business to generate a new link.</p>
                </div>
                :
                <div className="py-[80px]">
                    <Routes>
                        <Route path="/" element={<Navigate to="/customer/onboarding" />}/>
                        <Route path="/onboarding" element={<CustomerKYC />}/>
                        <Route path="/share-data" element={<LinkAccIndex />}/>
                        <Route path="/share-data/upload-pdf" element={<PdfForm />}/>
                        <Route path="/share-data/open-banking" element={<OpenBanking />}/>
                        <Route path="/edoc-success" element={<EDocSuccess />}/>
                        <Route path="/direct-debit/*" element={<DirectDebit />}/>
                    </Routes>
                </div>
            }
        </div>
    )
}