import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { IncomeService } from "services/income.service";
import IncomeFilter from "components/Modal/IncomeFilter";
import InsightKeywords from "components/Modal/InsightKeywords";
import InsightModal from "components/Modal/GenerateReport";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { logToBin } from "../../../lib/logs";
import { Mixpanel } from "../../../lib/mixpanel";
import Table from "components/Table";
import { Tooltip } from 'react-tooltip';
import { useSelector } from "react-redux";
import clsx from "clsx";
import { dateFormatter, handleRequestErrors, timeFormatter } from "utils/helpers";

import more from "../../../images/more.png";
import hourglass from "../../../images/gif/hourglass.gif";
import creditChek from "../../../images/svg/apps/creditChek.svg";
import filterLines from "../../../images/svg/income/filter-lines.svg";
import filterLinesInactive from "../../../images/svg/income/filter-linesInactive.svg";
import info from "../../../images/svg/income/info.svg";
import keywordSetting from "../../../images/svg/income/keywordSetting.svg";
import insightDone from "../../../images/svg/income/insight-done.svg";
import newAcc from "../../../images/svg/income/new_acc.svg";
import accParsed from "../../../images/svg/income/acc_parsed.svg";
import accError from "../../../images/svg/income/acc_error.svg";
import refreshTable from "../../../images/svg/income/refresh.svg";
import bankConnected from "../../../images/svg/income/bank_conn.svg";
import tv_W from "../../../images/svg/income/table/tv.svg";
import delete_W from "../../../images/svg/income/table/delete.svg";
import reload from "images/svg/income/reload.svg";


function Main ({setDetailName, permissionValues}) {
    let history = useNavigate();
    const [refreshModal, setRefreshModal] = useState(false);
    const [bvnValue, setBvnValue] = useState();
    const [arr, setArr] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [userRefreshAccs, setUserRefreshAccs] = useState([]);
    const [idToRefresh, setIdToRefresh] = useState({});
    const [oneAcc, setOneAcc] = useState("")
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const refOne = useRef(null)
    const [showFilter, setShowFilter] = useState(false);
    const [showKeywords, setShowKeywords] = useState(false);
    const [fetchedFilters, setFetchedFilters] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [showLoaderGlass, setShowLoaderGlass] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const b2b_data = useSelector(state => state?.loginData?.data)
    const [searchParams, setSearchParams] = useSearchParams();

    // for loading when view is clicked (next 2 lines), 3rd line for refresh
    const [disableActionRefresh, setDisableActionRefresh] = useState(false);
    const [index, setIndex] = useState(null);
    const [accAction, setAccAction] = useState("")

    useEffect(()=>{
        searchParams.set('tm', `${new Date().toISOString()}`);
        setSearchParams(searchParams);
        document.addEventListener("click", handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleClickOutside = (e) => {
        if(!refOne?.current?.contains(e.target)){
            setIndex(null)
        } else{
            // sth
        }
    }

    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(()=>{
        fetchFilters();
        fetchLinkedBorrowers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchLinkedBorrowers();
        }, 60000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchLinkedBorrowers = () => {
        let payload = JSON.stringify({
            businessId: b2b_data?.user?.businessId
        })
        IncomeService.getLinkedBorrowers(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    setData(res.data.reverse())
                    setDataLoading(false);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const fetchFilters = () => {
        IncomeService.getFilter(b2b_data?.user?.businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    if(res.data!==undefined){
                        setFetchedFilters(res.data.params)
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    useEffect(() => {
        const filteredData = data?.filter((item) => item?.fullname?.toLowerCase().includes(searchTerm?.toLowerCase()));
        setBvnValue(filteredData);
    }, [searchTerm, data])

    useEffect(() => {
        if(bvnValue?.length > 0){
            setArr(bvnValue)
            setDisplayData(arr); // 10 per page
        }
        else {
            setDisplayData([]);
        }
    }, [arr, bvnValue])

    const rerunInsight = (acc, borrowerId) => {
        let payload = JSON.stringify({
            borrowerId,
            accountNumber: acc===""?oneAcc:acc
        });
        IncomeService.rerunInsight(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setRefreshModal(false);
                setDisableActionRefresh(false);
                if (res.status === "success") {
                    toast.success("Income insight rerun successfully!")
                    fetchLinkedBorrowers()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setRefreshModal(false);
                setDisableActionRefresh(false);
                handleRequestErrors(err)
            });
    }

    const getView = (borrowerId, borrowerName) => {
        IncomeService.getIncomeInsightData(borrowerId)
            .then((response) => response.data)
            .then((res) => {
                setShowLoaderGlass(false);
                if (res.status === "success") {
                    sessionStorage.setItem( "incomeInsightData", JSON.stringify({ 
                        data: res.data.insight,
                        edti:res.data.EDTI,
                        name: borrowerName
                    }));
                    history(`/income/insight`);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowLoaderGlass(false);
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
     
        IncomeService.encryptText(JSON.stringify({text: borrowerId})).then(resp => {
            sessionStorage.setItem( "incomeBorrower", JSON.stringify({ data: resp }));
        })
    }

    const unlinkAcc = (acc, borrowerId) => {
        let payload = JSON.stringify({
            borrowerId,
            businessId: b2b_data?.user?.businessId,
            accountNumber: acc
        });
        IncomeService.doAccUnlink(payload)
            .then((response) => response.data)
            .then((res) => {
                setRefreshModal(false);
                setDisableActionRefresh(false);
                if (res.status === "success") {
                    toast.success("Account unlinked successfully!")
                    fetchLinkedBorrowers()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setRefreshModal(false);
                setDisableActionRefresh(false);
                toast.error("Deleting failed, please try again!")
            });
    }

    const retryInsight = (item) => {
        let payload = JSON.stringify({
            borrowerId: item._id
        })
        IncomeService.retryInsight(payload)
            .then((response) => response.data)
            .then((res) => {
                if(res.status==="success"){
                    fetchLinkedBorrowers()
                    toast.success('Insight retried successfully!')
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div>
                <div className="income_keywords">
                    <div className="home_main-topText">
                        <h5>Linked Accounts</h5>
                        <div className="home_main-topText-search mt-1 mb-4" >
                            <p>All customers with accounts linked to {b2b_data?.businessName}</p>
                        </div>
                    </div>
                    <div className="income_keywords-info">
                        <img src={info} alt="info" onMouseEnter={()=>setShowInfo(true)} onMouseLeave={()=>setShowInfo(false)} />
                        {
                            showInfo &&
                            <div className="income_keywords-info-content">
                                <p>This allows your business to enhance the accuracy of creditchek’s default cognitive algorithm using your preferred  custom keywords to help detect more ‘recurring debt’ and ‘expenses’ in your customer’s analyzed bank account transaction data.</p>
                            </div>
                        }
                    </div>
                    <button onClick={()=>setShowKeywords(true)} className="flex items-center gap-x-1 py-[7px] px-2.5 bg-white border-[1px] border-[#D0D5DD} text-[#344054] rounded-[6px] ml-auto shrink-0">
                        <img src={keywordSetting} alt="" /><p>Customize Insight Keywords</p>
                    </button>
                </div>
                <div className="income_editFilter" >
                    <button 
                        className={permissionValues?.includes("edit")?"flex items-center":'flex items-center btnInactive'}
                        onClick={()=> permissionValues?.includes("edit") ? setShowFilter(true) :
                            toast.error("You are not authorized to perform this action!!!")
                        }
                    >
                        <img src={permissionValues?.includes("edit")?filterLines:filterLinesInactive} alt="" /><p>Edit Filters</p>
                    </button>
                    <div>
                        <p>Active Filters:</p>
                        {
                            fetchedFilters.length===0 ?
                            <p className="income_editFilter_filters"><div className="dot"></div>No active filters</p>
                            : 
                            <p className="income_editFilter_filters">
                                <div className="dot"></div>
                                {
                                    fetchedFilters?.map((item, idx)=>(
                                        <>
                                            {
                                                Object.keys(item).map(oneKey => oneKey)?.[0] === 'averageMonthlyEdti' ? 
                                                    <>
                                                        {`Debt Burden Ratio ${item?.averageMonthlyEdti?.queryOperator==="more"?"more than"
                                                            :item?.averageMonthlyEdti?.queryOperator==="less"?"less than"
                                                            :item?.averageMonthlyEdti?.queryOperator==="equal"?"equal to"
                                                            :item?.averageMonthlyEdti?.queryOperator} ${" "}
                                                        ${item?.averageMonthlyEdti?.start} ${" "}
                                                        ${item?.averageMonthlyEdti?.queryOperator==="between"?`- ${item?.averageMonthlyEdti?.end}`
                                                            :''}`} 
                                                        {fetchedFilters.length !== idx+1 && <span className="income_editFilter_filters-query">{item?.averageMonthlyEdti?.queryCondition} </span>}
                                                    </>
                                                : Object.keys(item).map(oneKey => oneKey)?.[0] === 'averageMonthlyIncome' ? 
                                                    <>
                                                        {`Average Monthly Income ${item?.averageMonthlyIncome?.queryOperator==="more"?"more than"
                                                            :item?.averageMonthlyIncome?.queryOperator==="less"?"less than"
                                                            :item?.averageMonthlyIncome?.queryOperator==="equal"?"equal to"
                                                            :item?.averageMonthlyIncome?.queryOperator} ${" "}
                                                        ${item?.averageMonthlyIncome?.start} ${" "}
                                                        ${item?.averageMonthlyIncome?.queryOperator==="between"?`- ${item?.averageMonthlyIncome?.end}`
                                                            :''}`} 
                                                        {fetchedFilters.length !== idx+1 && <span className="income_editFilter_filters-query">{item?.averageMonthlyIncome?.queryCondition} </span>}
                                                    </>
                                                : Object.keys(item).map(oneKey => oneKey)?.[0] === 'averageMonthlyBalance' ? 
                                                    <>
                                                        {`Average Monthly Balance ${item?.averageMonthlyBalance?.queryOperator==="more"?"more than"
                                                            :item?.averageMonthlyBalance?.queryOperator==="less"?"less than"
                                                            :item?.averageMonthlyBalance?.queryOperator==="equal"?"equal to"
                                                            :item?.averageMonthlyBalance?.queryOperator} 
                                                        ${item?.averageMonthlyBalance?.start} 
                                                        ${item?.averageMonthlyBalance?.queryOperator==="between"?`- ${item?.averageMonthlyBalance?.end}`
                                                            :''}`}
                                                        {fetchedFilters.length !== idx+1 && <span className="income_editFilter_filters-query">{item?.averageMonthlyBalance?.queryCondition} </span>}
                                                    </>
                                                :  Object.keys(item).map(oneKey => oneKey)?.[0]
                                            }
                                        </>
                                    ))
                                }
                            </p>
                        }
                    </div>
                </div>
                {/* class for the scroll --- className="table table_scroll-general" */}
                <Table
                    tableTitle={"Linked Accounts"}
                    searchPlaceHolder={"Search by customer name"}
                    onSearchChange={handleChange}
                    data={displayData}
                    loading={dataLoading}
                    hideSearch={false}
                    trClassName="!border-none"
                    theadClassName="!border-none whitespace-nowrap"
                    tableClassName="bg-[#F9FAFB] 2xl:min-w-[1300px]"
                    rows={[
                        {
                            header:'Last Updated',
                            view: (item) => (
                                <span className="text-sm py-[10px] pl-[10px] whitespace-nowrap font-semibold">
                                    {dateFormatter(item?.accounts?.[item?.accounts.length-1]?.dateLinked, 'dd/mm/yyyy')}
                                </span>
                            ),
                        },
                        {
                            header:'Name',
                            view: (item) => (
                                <div className="py-[10px] pl-[10px] text-sm relative w-fit">
                                    <span>{item?.fullname}</span>
                                    <span 
                                        data-tooltip-id={item._id}
                                        data-tooltip-place="right"
                                        className={clsx(
                                            'absolute w-2.5 top-1.5 -right-2.5 h-2.5 cursor-pointer rounded-full',
                                            item.status==="new_account_added" ? "bg-cc-primary"
                                            : item.status==="insights_ready" ? "bg-cc-green-1"
                                            : item.status==="account_linked" ? "bg-cc-yellow-3"
                                            : item.status==="account_parsed" ? "bg-cc-brown-3"
                                            : "bg-cc-red-11"
                                        )}
                                    >
                                    </span>
                                    <Tooltip 
                                        id={item._id}
                                        render={()=>(
                                            <div className="text-xs">
                                                <div className='flex items-center'>
                                                    <img 
                                                        src={
                                                            item.status==="new_account_added" ? newAcc
                                                            : item.status==="insights_ready" ? insightDone
                                                            : item.status==="account_linked" ? bankConnected
                                                            : item.status==="account_parsed" ? accParsed
                                                            : accError
                                                        } 
                                                        alt="" 
                                                        className="w-[30px] mr-1" 
                                                    />
                                                    <p className="w-[150px] normal-case">
                                                        {
                                                            item.status==="new_account_added" ? "New bank account data detected, recalculate to update existing income insights."
                                                            : item.status==="insights_ready" ? "Actionable insights about this customer now available for review"
                                                            : item.status==="account_linked" ? "Customer bank Statement uploaded successfully, now undergoing processing"
                                                            : item.status==="account_parsed" ? "The latest uploaded statement is successfully processed and available for IncomeInsight Analysis"
                                                            : item.status==="error_parsing" ? "There was an error with the latest bank statement upload"
                                                            : "Failure detected processing the latest PDF statement"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            ),
                        },
                        {
                            header:'Mobile',
                            view: (item) => (
                                <span className="py-[10px] text-sm pl-[10px]">{item.phones?.[0]===null||item.phones.length===0?'n/a':item.phones?.[0]}</span>
                            ),
                        },
                        {
                            header:'Email',
                            view: (item) => (
                                <span className="lowercase text-sm py-[10px] pl-[10px]">{item.emails?.[0]===""||item.emails.length===0?'not available':item.emails?.[0]}</span>
                            ),
                        },
                        {
                            header:'Banks',
                            view: (item) => (
                                <div className="relative w-[75px] h-[47px]">
                                    {
                                        item?.accounts?.length>0 &&
                                        <div className="rounded-full w-[30px] h-[30px] absolute border-[2px] border-solid border-red overflow-hidden top-[5px] left-0 bg-white">
                                            <img className="w-full h-auto" src={ngBanks.find(o => o.code === item?.accounts?.[0]?.institution?.bankCode)?.logo?.url ? ngBanks.find(o => o.code === item?.accounts?.[0]?.institution?.bankCode)?.logo?.url : creditChek} alt="banklogo" />
                                        </div>
                                    }
                                    {
                                        item?.accounts?.length>1 &&
                                        <div className="rounded-full w-[30px] h-[30px] absolute border-[2px] border-solid border-red overflow-hidden top-[5px] left-[20px] bg-white">
                                            <img className="w-full h-auto" src={ngBanks.find(o => o.code === item?.accounts?.[1]?.institution?.bankCode)?.logo?.url ? ngBanks.find(o => o.code === item?.accounts?.[1]?.institution?.bankCode)?.logo?.url : creditChek} alt="banklogo" />
                                        </div>
                                    }
                                    {
                                        item?.accounts?.length>2 &&
                                        <div className="rounded-full w-[30px] h-[30px] absolute border-[2px] border-solid border-red overflow-hidden top-[5px] left-[40px] bg-cc-blue-2">
                                            <p className="text-[1.1em] font-semibold text-white mt-[3px] text-center">+{item?.accounts?.length - 2}</p>
                                        </div>
                                    }
                                </div>
                            ),
                        },
                        {
                            header:<div className="flex items-center gap-x-2"><p>Status</p><img src={refreshTable} alt="refresh" className="cursor-pointer" onClick={()=>{setDataLoading(true);fetchLinkedBorrowers();}} /></div>,
                            view: (item) => (
                                <div className="py-[10px] pl-[10px] flex">
                                    <div 
                                        className={clsx(
                                            "flex items-center px-2 rounded-2xl cursor-pointer",
                                            item.status==="new_account_added" ? "bg-cc-blue-15"
                                            : item.status==="insights_ready" ? "bg-[#47D8004F]"
                                            : item.status==="account_linked" ? "bg-cc-yellow-4"
                                            : item.status==="account_parsed" ? "bg-cc-brown-2"
                                            : "bg-cc-red-6"
                                        )}
                                        data-tooltip-id={`${item._id}_status`}
                                        data-tooltip-place="top"
                                    >
                                        <div
                                            className={clsx(
                                                "w-1.5 h-1.5 rounded-full mr-1",
                                                item.status==="new_account_added" ? "bg-cc-primary"
                                                : item.status==="insights_ready" ? "bg-cc-green-11"
                                                : item.status==="account_linked" ? "bg-cc-yellow-3"
                                                : item.status==="account_parsed" ? "bg-cc-brown-1"
                                                : "bg-cc-red-11"
                                            )}
                                        ></div>
                                        <span 
                                            className={clsx(
                                                "text-sm whitespace-nowrap",
                                                item.status==="new_account_added" ? "text-cc-primary"
                                                : item.status==="insights_ready" ? "text-cc-green-11"
                                                : item.status==="account_linked" ? "text-cc-yellow-3"
                                                : item.status==="account_parsed" ? "text-cc-brown-1"
                                                : "text-cc-red-11"
                                            )}
                                        >
                                            {
                                                item.status==="new_account_added" ? "New account connected"
                                                : item.status==="insights_ready" ? "Insight successful"
                                                : item.status==="account_linked" ? "Bank data connected"
                                                : item.status==="account_parsed" ? "Bank data processed"
                                                : item.status==="error_parsing" ? 'Error occured'
                                                : 'Failure on new PDF'
                                            }
                                        </span>
                                    </div>
                                    <Tooltip 
                                        id={`${item._id}_status`}
                                        render={()=>(
                                            <div className="text-xs">
                                                <div className='flex items-center'>
                                                    <img 
                                                        src={
                                                            item.status==="new_account_added" ? newAcc
                                                            : item.status==="insights_ready" ? insightDone
                                                            : item.status==="account_linked" ? bankConnected
                                                            : item.status==="account_parsed" ? accParsed
                                                            : accError
                                                        } 
                                                        alt="" 
                                                        className="w-[30px] mr-1" 
                                                    />
                                                    <p className="w-[150px] normal-case">
                                                        {
                                                            item.status==="new_account_added" ? "New bank account data detected, recalculate to update existing income insights."
                                                            : item.status==="insights_ready" ? "Actionable insights about this customer now available for review"
                                                            : item.status==="account_linked" ? "Customer bank Statement uploaded successfully, now undergoing processing"
                                                            : item.status==="account_parsed" ? "The latest uploaded statement is successfully processed and available for IncomeInsight Analysis"
                                                            : item.status==="error_parsing" ? "There was an error with the latest bank statement upload"
                                                            : "Failure detected processing the latest PDF statement"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            ),
                        },
                        {
                            header:'Date Connected',
                            view: (item) => (
                                <div className="py-[10px] pl-[10px] flex">
                                    <div className="flex items-center px-2 rounded-2xl bg-cc-blue-16">
                                        <span className="text-sm text-cc-primary whitespace-nowrap">
                                            {dateFormatter(item?.accounts?.[0]?.dateLinked, 'dd/mm/yyyy')}, {timeFormatter(item?.accounts?.[0]?.dateLinked)}
                                        </span>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            header:'Action',
                            view: (item) => (
                                <div className="relative h-[30px] w-[40px] ml-3">
                                    <div className="rounded-full m-3 w-[25px] h-[25px] cursor-pointer overflow-hidden bg-cc-primary block m-auto" onClick={()=>index===null?setIndex(item._id):setIndex(null)} >
                                        <img src={more} alt="" className="absolute top-[10px] left-[11px]"/>
                                    </div>
                                    {
                                        item._id===index &&
                                        <div className="absolute top-[28px] right-[5px] bg-white rounded-[5px] shadow-md w-[100px] z-[2] p-2.5" ref={refOne}>
                                            {
                                                (item.status==="error_parsing"||item.status==="error_parsing_new_pdf") &&
                                                <button 
                                                    className="flex items-center text-sm mb-2.5 text-white bg-cc-primary py-1.5 px-2.5 w-[80px] rounded-[6px]"
                                                    onClick={()=>{
                                                        retryInsight(item)
                                                    }}
                                                >
                                                    <img src={reload} alt="" className="w-[15px] mr-1" />
                                                    <span>Retry</span>
                                                </button>
                                            }
                                            {
                                                (item.status==="insights_ready") &&
                                                <button 
                                                    className="flex items-center text-sm mb-2.5 text-white bg-cc-green-1 py-1.5 px-2.5 w-[80px] rounded-[6px]"
                                                    onClick={()=>{
                                                        setAccAction("rerun");
                                                        setUserRefreshAccs(item?.accounts);
                                                        if(item?.accounts?.length < 2){
                                                            setOneAcc(item?.accounts[0]?.accountNumber);
                                                        }
                                                        setIdToRefresh(item?._id);
                                                        permissionValues?.includes("edit") ? setRefreshModal(true) :
                                                        toast.error("You are not authorized to perform this action!!!")
                                                    }}
                                                >
                                                    <img src={reload} alt="" className="w-[15px] mr-1" />
                                                    <span>Rerun</span>
                                                </button>
                                            }
                                            {
                                                (item.status==="insights_ready"||item.status==="new_account_added"||item.status==="error_parsing_new_pdf") &&
                                                <button 
                                                    className="flex items-center text-sm mb-2.5 text-white bg-cc-primary py-1.5 px-2.5 w-[80px] rounded-[6px]"
                                                    onClick={()=>{
                                                        getView(item._id, item.fullname);
                                                        setShowLoaderGlass(true);
                                                        Mixpanel.track(`View linked account button was clicked`, {
                                                            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                            bussinessName: b2b_data?.businessName
                                                        });
                                                    }}
                                                >
                                                    <img src={tv_W} alt="" className="w-[15px] mr-1" />
                                                    <span>View</span>
                                                </button>
                                            }
                                            <button 
                                                className="flex items-center text-sm text-white bg-cc-red-2 py-1.5 px-2.5 w-[80px] rounded-[6px]"
                                                onClick={()=>{
                                                    setAccAction("delete");
                                                    setUserRefreshAccs(item?.accounts);
                                                    setIdToRefresh(item?._id);
                                                    permissionValues?.includes("edit") ? setRefreshModal(true) :
                                                    toast.error("You are not authorized to perform this action!!!")
                                                    Mixpanel.track(`Unlink account button was clicked`, {
                                                        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                        bussinessName: b2b_data?.businessName
                                                    });
                                                }}
                                            >
                                                <img src={delete_W} alt="" className="w-[15px] mr-1" />
                                                <span>Unlink</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
            <InsightModal 
                show={refreshModal}
                cancel={()=>{
                    setRefreshModal(false)
                }} 
                data={"none"}
                refreshData={userRefreshAccs}
                doAction={(accNo)=>{
                    if(accAction==='rerun'){
                        rerunInsight(accNo, idToRefresh);
                    } else {
                        unlinkAcc(accNo, idToRefresh);
                    }
                    setDisableActionRefresh(true);
                }}
                reportType={accAction}
                message={
                    accAction==='rerun' ? "Please be informed that this assessment attracts a charge from your wallet"
                    : "Delete or unlink borrowers bank account from your business"
                }
                disableAction={disableActionRefresh}
            />
            <div style={{height:"50px"}} />
            <IncomeFilter 
                show={showFilter} 
                handleClose={()=>{
                    setShowFilter(false);
                    fetchFilters();
                }} 
                runFilterData={(data)=>{
                    setData(data); 
                    setDataLoading(false);
                    setShowFilter(false);
                }} 
                setLoading={()=>setDataLoading(true)}
                formerFilter={fetchedFilters}
            />
            <InsightKeywords
                show={showKeywords} 
                handleClose={()=>{
                    setShowKeywords(false);
                }} 
            />
            <Modal show={showLoaderGlass} size="md" onHide={()=>setShowLoaderGlass(false)} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="m-[20px] text-center">
                    <img src={hourglass} alt="" className="w-[50px] mt-0 mx-auto mb-[20px] block"/>
                    <h5 className="text-lg font-medium">Fetching report...</h5>
                    <p>Just a moment please</p>
                </div>
            </Modal>
        </div>
    )
}

export default Main;