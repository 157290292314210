import { useEffect, useState } from "react";
import { OtherService } from "services/other.service";

export const useGeolocation = () => {
    const [data, setData] = useState(null)

    useEffect(() => {
        OtherService.getGeolocation()
            .then(response => response.data)
            .then((res) => {
                setData(res)
            })
            .catch(()=>{})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ipcountry: data?.country_name,
        ipaddress: data?.IPv4,
        city: data?.city
    }
};