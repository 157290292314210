import { currencyFormatter } from "utils/helpers";

import lastDeposit from "images/svg/income/last-deposit.svg";
import lastWithdrawal from "images/svg/income/last-withdrawal.svg";
import highestBalance from "images/svg/income/highest-balance.svg";
import highestBand from "images/svg/income/highest-band.svg";
import spike from "images/svg/income/spike.svg";
import tableIcon from "images/svg/loan-performance.svg";


export default function CashFlowHighlight({data, disposableIncome}){
    return(
        <div>
            <div className="mb-6 border-[1px] border-[#D0D5DD] flex items-center gap-1.5 p-2.5 rounded">
                <img src={tableIcon} alt="icon" />
                <p className="text-[18px] font-medium">Cashflow Highlights</p>
            </div>
            <div className="grid grid-cols-3 gap-x-6 lg:grid-cols-2">
                {
                    [
                        {
                            title: "Average Daily Balance",
                            value: currencyFormatter(data?.averageDailyBal/100 || 0, 2, "NGN"),
                            icon: highestBalance,
                        },
                        {
                            title: "Highest Balance",
                            value: currencyFormatter(data?.highestBalance/100 || 0, 2, "NGN"),
                            icon: highestBalance,
                        },
                        {
                            title: "Disposable Income",
                            value: currencyFormatter(disposableIncome/100 || 0, 2, "NGN"),
                            icon: highestBalance,
                        },
                        {
                            title: "Average Withdrawal",
                            value: currencyFormatter(data?.meanDebit/100 || 0, 2, "NGN"),
                            icon: lastWithdrawal,
                        },
                        {
                            title: "Highest Withdrawal",
                            value: currencyFormatter(data?.maxDebit/100 || 0, 2, "NGN"),
                            icon: lastWithdrawal,
                        },
                        {
                            title: "Lowest Withdrawal",
                            value: currencyFormatter(data?.minDebit/100 || 0, 2, "NGN"),
                            icon: lastWithdrawal,
                        },
                        {
                            title: "Average Deposit",
                            value: currencyFormatter(data?.meanCredit/100 || 0, 2, "NGN"),
                            icon: lastDeposit,
                        },
                        {
                            title: "Highest Deposit",
                            value: currencyFormatter(data?.maxCredit/100 || 0, 2, "NGN"),
                            icon: lastDeposit,
                        },
                        {
                            title: "Lowest Deposit",
                            value: currencyFormatter(data?.min_crebit/100 || 0, 2, "NGN"),
                            icon: lastDeposit,
                        },
                        {
                            title: "Percentage Credit on Transactions",
                            value: `${Math.round(data?.perCredTrans || 0)}%`,
                            icon: highestBand,
                        },
                        {
                            title: "Percentage Debit on Transactions",
                            value: `${Math.round(data?.perDebTrans || 0)}%`,
                            icon: spike,
                        },
                    ].map((item, idx)=>(
                        <div key={idx}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top">
                                    <p>{item.title}</p>
                                    <img src={item.icon} alt="icon" className="credit_boxes-single-patternIcon" />
                                </div>
                                <p className="credit_boxes-single-patternText">{item.value}</p>
                                {item?.slot}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}