import axios from 'axios';


let isDev = process.env.REACT_APP_NODE_ENV === 'development'
let baseURL = isDev ? 
    process.env.REACT_APP_Dev_Cluster
    :
    process.env.REACT_APP_Production_Cluster

export const APIKit = (timeout) => axios.create({
    baseURL,
    timeout: timeout, // timeout can be increased
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
    },
});