import { deviceDetect } from "react-device-detect";

export const useDeviceInfo = () => {
    const device = deviceDetect()

    return {
        isBrowser: device.isBrowser || true,
        browserMajorVersion: device.browserMajorVersion || device.osVersion || "",
        browserFullVersion: device.browserFullVersion || device.osVersion || "",
        browserName: device.browserName || device.model || "",
        engineName: device.engineName || device.os || "",
        engineVersion: device.engineVersion || device.osVersion || "",
        osName:  device.osName || device.os || "",
        osVersion: device.osVersion || "",
        userAgent: device.userAgent || device.ua || ""
    }
};