import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IdentityService } from "services/identity.service";
import NewShare from "components/Modal/NewShare";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../../lib/mixpanel";
import Table from "components/Table";
import bank from "images/svg/income/bank.svg";
import { handleRequestErrors } from "utils/helpers";
import { IoMdInformationCircle } from "react-icons/io"
import { Tooltip } from "react-tooltip";
import clsx from "clsx";


function Main ({setDetailName, permissionValues}) {
    const [showModal, setShowModal] = useState(false)
    const [bvnValue, setBvnValue] = useState();
    const [arr, setArr] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [idToShare, setIdToShare] = useState("");
    const [showModalType, setShowModalType] = useState("");
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        IdentityService.getBorrowers()
            .then((response) => response.data)
            .then((res) => {
                if (res.status === true) {
                    setData(res.data.reverse())
                    setIsLoading(false)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }, [])

    useEffect(() => {
        if(bvnValue?.length > 0){
            setArr(bvnValue)
            // setLoaded(true)
            setDisplayData(arr) // 10 per page
        }
        else {
            setDisplayData([]);
            // setArr([]);
        }
    }, [arr, bvnValue])
    
    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);
   
    useEffect(()=>{
        const filteredData = data?.filter((item) => item?.bvn?.includes(searchTerm) || item?.phones[0]?.includes(searchTerm) || item?.fullname?.toLowerCase().includes(searchTerm?.toLowerCase()) );
        setBvnValue(filteredData);
    },[searchTerm, data]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div>
                <div className="home_main-topText">
                    <h5>Registered Customers</h5>
                </div>
                <div className="mt-[10px] flex items-center gap-3 sm:gap-1.5 sm:flex-col sm:items-start">
                    <p>Onboard new customers</p>
                    <button 
                        className={clsx(
                            "flex items-center gap-1.5 bg-[#0046e6] py-[7px] px-[15px] text-white rounded-[8px] text-sm",
                            !permissionValues?.includes("edit") && "bg-[#8A8A8A]"
                        )} 
                        onClick={() => {
                            setShowModalType("share-whatsapp"); 
                            permissionValues?.includes("edit") ? setShowModal(true) : toast.error("You are not authorized to perform this action!!!");
                            Mixpanel.track(`Income Insight (Share link) button was clicked`, {
                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                bussinessName: b2b_data?.businessName
                            });
                        }}
                    >
                        <p>Generate Magic Link</p>
                        <IoMdInformationCircle 
                            data-tooltip-id={"link-message"} 
                            data-tooltip-content={"This link expires in 12 hours"} 
                            data-tooltip-place="right"
                            className="w-[14px] scale-[1.2] text-[#DE213C]" 
                        />
                        <Tooltip 
                            id={"link-message"} 
                            place="left"
                            className="bg-black"
                            // render={()=>(
                            //     <div className="text-xs text-white">
                            //         <p>"This link expires in 12 hours"</p>
                            //     </div>
                            // )} 
                        />
                    </button>
                </div>
                <Table
                    tableTitle={"Registered Customers"}
                    searchPlaceHolder={"Search by BVN or phone number"}
                    onSearchChange={handleChange}
                    data={displayData}
                    hideSearch={false}
                    loading={isLoading}
                    trClassName="!border-none"
                    theadClassName="!border-none"
                    tableClassName="bg-[#F9FAFB] 2xl:min-w-[1200px]"
                    rows={[
                        {
                            header:'CUSTOMER NAME',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.fullname}</span>
                            ),
                        },
                        {
                            header:'MOBILE',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item.phones?.[0]===null||item.phones.length===0?'n/a':item.phones?.[0]}</span>
                            ),
                        },
                        {
                            header:'EMAIL',
                            view: (item) => (
                                <span className="lowercase py-[10px] pl-[10px] pr-[5px]">{item.emails?.[0]===""||item.emails.length===0?'not available':item.emails?.[0]}</span>
                            ),
                        },
                        {
                            header:'BVN',
                            view: (item) => (
                                <span className="py-[5px] rounded-[6px] px-[5px]">{item.bvn}</span>
                            ),
                        },
                        {
                            header:'MARITAL STATUS',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item.maritalStatus===""||!item.maritalStatus?'n/a':item.maritalStatus}</span>
                            ),
                        },
                        {
                            header:'',
                            view: (item) => (
                                <span>
                                    <button 
                                        className={`${permissionValues?.includes("edit")?"bg-cc-primary":"bg-cc-grey-3"} text-[0.8em] m-2.5 py-[3px] px-2.5 text-white rounded-[4px] flex items-center`}
                                        onClick={()=>{
                                            permissionValues?.includes("edit") ? setShowModal(true) :
                                            toast.error("You are not authorized to perform this action!!!") ; 
                                            setIdToShare(item._id); setShowModalType("share-linkAcc")
                                        }}
                                    >
                                        <img src={bank} alt="link" className="w-[15px] mr-[5px]" />
                                        <p>Link Account</p>
                                    </button>
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
            <NewShare
                show={showModal}
                type={showModalType}
                borrowerId={idToShare}
                cancel={()=>setShowModal(false)}
                doAction={()=>{
                    setShowModal(false);
                    setIdToShare("")
                }}
            />
        </div>
    )
}

export default Main;