import { useEffect, useState } from "react";
import calendar from "images/svg/income/calendar.svg";
import calendarWhite from "images/svg/income/calendarWhite.svg";
import OutsideClickHandler from 'react-outside-click-handler';
import MonthRange from "../MonthRange";
import Donut from "../Chart/Donut";
import { currencyFormatter } from "utils/helpers";
import tableIcon from "images/svg/loan-performance.svg";


export default function SpendingCategories ({name}) {
    const [filterSingle, setFilterSingle] = useState('Select Month')
    const [filterValue, setFilterValue] = useState({
        months: [],
        activeKeys: [],
        customStart: 0,
        customEnd: 0,
    })
    const [edtiData, setEdtiData] = useState({
        tableFor: "edti",
        headings: ["Month", "Amount"],
        items: []
    });
    const topNav = "spending"
    const [showSingleOptions, setShowSingleOptions] = useState(false);
    const colors = ['#DB5ABA', '#1B60FC', '#C7E8F3', '#8DCE8A', '#FADADD', '#152E0A', '#6DE833', '#A567FF', '#FFA567', '#696969', '#FF6666', '#FFD700', 'red', 'green', "blue"];

    let incomeData = {};

    try {
        let details = sessionStorage?.getItem('incomeInsightData');
        incomeData = JSON?.parse(details);
    } catch (err) {
        //err
    }

    useEffect(()=>{
        const objArray = [];
        if(filterSingle!=='Select Month'){
            filterValue.activeKeys.forEach((item)=>{
                objArray.push({
                    name: item, 
                    value: incomeData?.edti[`${item}`][`${filterSingle}`]===undefined?0:incomeData?.edti[`${item}`][`${filterSingle}`]
                })
            })
            setEdtiData({
                ...edtiData,
                items: objArray,
                headings: ["Action", "Amount"]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSingle])

    const getDatesInRange = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const newDate = [];
        filterValue?.months?.forEach((item)=>{
            const itemDate = new Date(item);
            if(itemDate >= start && itemDate <= end){
                newDate.push(item)
            }
        })
            
        return newDate;
    }

    useEffect(()=>{
        const chosenRange = getDatesInRange(filterValue?.months?.[filterValue?.customStart-1], filterValue?.months?.[filterValue?.customEnd-1])
        const customArray = []

        if(filterValue.customStart!==0 && filterValue.customEnd!==0){
            filterValue?.activeKeys?.forEach((item)=>{
                const valuesArray = [0]
                chosenRange.forEach((monthItem)=>{
                    valuesArray.push(incomeData.edti[`${item}`][`${monthItem}`]===undefined?0:incomeData.edti[`${item}`][`${monthItem}`])
                })
                const valuesArraySum = valuesArray?.reduce(function(a,b){
                    return a+b;
                });
                customArray.push({
                    name: item, 
                    value: valuesArraySum
                })
            })
            setEdtiData({
                ...edtiData,
                items: customArray,
                headings: ["Action", "Amount"]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue])

    useEffect(()=>{
        try{

            let spending = incomeData?.edti?.spendingAnalysis;
            const result = [];
            const activeKeys = [];
            Object.keys(spending).forEach(key => {
                result.push({name: key, value: spending[key][0]});
                if(spending[key][0]!==0){
                    activeKeys.push(key)
                }
            });
            setFilterValue({
                ...filterValue,
                activeKeys: activeKeys
            })
            setEdtiData({
                ...edtiData,
                items: result,
                headings: ["Action", "Amount"]
            })
        } catch {}
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[topNav])

    return (
        <div className="w-full border-[1px] border-[#D0D5DD] p-[20px] my-8 rounded-[10px] box-border">
            <div className="my-4 flex items-center gap-1.5">
                <img src={tableIcon} alt="icon" />
                <p className="text-[18px] font-medium">Spending Categories</p>
            </div>
            <div className="flex">
                <p className="border-b-[1px] pb-2 mb-2">Here’s a view of the spending categories for {name}</p>
            </div>
            <div style={{display:'flex', margin:'0 0 20px -10px'}}>
                <div className={filterSingle==="Select Month"?"home_main-topText-filter":"home_main-topText-filter income-filterActive"} onClick={() => setShowSingleOptions(true)}>
                    <p className={filterSingle==="Select Month"?'flex items-center':'income-filterActive-text flex items-center'}>
                        <img src={filterSingle==="Select Month"?calendar:calendarWhite} alt="" />
                        <span>{filterSingle==="Select Month" ? filterSingle : `${filterSingle?.substring(0,3)} ${filterSingle?.split(" ")[1]}`}</span>
                    </p>
                    {
                        showSingleOptions &&
                        <div className="income_spending-box-filter-options" style={{minWidth: '100px', overflowY: 'scroll'}}>
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setShowSingleOptions(false)
                                }}
                            >
                                {
                                    filterValue?.months?.map((item, idx)=>(
                                        <>
                                            <p 
                                                onClick={()=>{
                                                    setFilterSingle(item);
                                                    setFilterValue({...filterValue, customStart: 0, customEnd: 0})
                                                }}
                                            >
                                                {item?.substring(0,3)} {item?.split(" ")[1]}
                                            </p>
                                            {(filterValue?.months?.length-1) !== idx && <hr/>}
                                        </>
                                    ))
                                }
                            </OutsideClickHandler>
                        </div>
                    }
                </div>
                <MonthRange 
                    data={filterValue} 
                    setSelectionRange={(item1, item2)=>{
                        setFilterValue({...filterValue, customStart: item1, customEnd: item2});
                        setFilterSingle('Select Month')
                    }} 
                />
            </div>
            <div className="income_spending-box-data">
                <Donut data={edtiData} colors={colors}/>
                <div className="rep">
                    {
                        edtiData?.items?.map((item, idx) => (
                            item?.value!==0 &&
                            <div key={idx}>
                                <span style={{background:`${colors[idx]}`}} ></span><p>{item?.name}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="max-w-[630px]">
                <hr className="my-3"/>
                <div className="grid grid-cols-3 sm:grid-cols-2 gap-6">
                    {
                        edtiData?.items?.map((item, idx) => (
                            item?.value!==0 &&
                            <div key={idx}>
                                <div className="flex justify-between border-[0.1px] border-[#4C8BF5] rounded-[12px] p-2.5 shadow-[0px_2.95px_66.45px_0px_#A3ABB93D]">
                                    <p className="text-xs font-bold opacity-50 capitalize">{item?.name}<br/><span className="text-lg font-normal opacity-100">{currencyFormatter(item?.value/100, 2, "NGN")}</span></p>   
                                    <div 
                                        className="w-[30px] h-[30px] rounded-full flex items-center justify-center"
                                        style={{background: colors[idx]}}
                                    >
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_23631_37572)">
                                                <path d="M13.5463 7.95842C13.1311 7.5433 12.5681 7.31008 11.981 7.31008C11.3939 7.31008 10.8309 7.5433 10.4158 7.95842C10.0007 8.37355 9.76744 8.93658 9.76744 9.52366C9.76744 10.1107 10.0007 10.6738 10.4158 11.0889C10.6213 11.2944 10.8654 11.4575 11.1339 11.5687C11.4025 11.68 11.6903 11.7372 11.981 11.7372C12.2717 11.7372 12.5596 11.68 12.8281 11.5687C13.0967 11.4575 13.3407 11.2944 13.5463 11.0889C13.7518 10.8833 13.9148 10.6393 14.0261 10.3708C14.1373 10.1022 14.1946 9.81435 14.1946 9.52366C14.1946 9.23297 14.1373 8.94512 14.0261 8.67656C13.9148 8.40799 13.7518 8.16397 13.5463 7.95842ZM6.07447 5.09375V13.9536H17.8876V5.09375H6.07447ZM16.4109 11.0003C16.0196 11.0003 15.6431 11.1553 15.3699 11.4359C15.0893 11.7091 14.9343 12.0856 14.9343 12.4769H9.02775C9.02775 12.0856 8.8727 11.7091 8.59214 11.4359C8.31896 11.1553 7.94242 11.0003 7.55111 11.0003V8.04702C7.94242 8.04702 8.31896 7.89197 8.59214 7.61141C8.8727 7.33824 9.02775 6.96169 9.02775 6.57039H14.9343C14.9343 6.96169 15.0893 7.33824 15.3699 7.61141C15.6431 7.89197 16.0196 8.04702 16.4109 8.04702V11.0003ZM4.59784 6.57039H3.1212C2.71513 6.57039 2.38289 6.23814 2.38289 5.83207C2.38289 5.42599 2.71513 5.09375 3.1212 5.09375H4.59784V6.57039ZM4.59784 10.262H2.38289C1.97681 10.262 1.64457 9.92973 1.64457 9.52366C1.64457 9.11758 1.97681 8.78534 2.38289 8.78534H4.59784V10.262ZM4.59784 13.9536H1.64457C1.23702 13.9536 0.90625 13.6213 0.90625 13.2152C0.90625 12.8092 1.23702 12.4769 1.64457 12.4769H4.59784V13.9536Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_23631_37572">
                                                    <rect width="17.7196" height="17.7196" fill="white" transform="translate(0.90625 0.664062)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div> 
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}