import { toast } from "react-toastify";
import { AuthService } from "services/auth.service";
import { handleRequestErrors } from "utils/helpers";


export const resendVerificationCode = (email, buss_id, buss_name, userId) => {
    let payload = JSON.stringify({
        email: email,
        userId,
    })

    AuthService.ResendVerificationAction(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === "success") {
                sessionStorage.setItem('otp', res?.data?.otp)
                window.location=`/auth/verification/email-verify/${email}/${buss_name}/${buss_id}/${userId}` //incase they want to resend verification code we just pick email from params
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err, true)
        });

    return null;
}