import { getDateFromDayinYear } from "utils/helpers";

import lastDeposit from "images/svg/income/last-deposit.svg";
import highestBalance from "images/svg/income/highest-balance.svg";
import tableIcon from "images/svg/loan-performance.svg";


export function TransactionPatterns ({data, forYear}) {
    const firstMonth = Object.entries(forYear)?.reverse()?.[0]?.[0];
    const getYear = firstMonth.substring(firstMonth.length-4, firstMonth.length);

    return (
        <div>
            <div className="mb-6 border-[1px] border-[#D0D5DD] flex items-center gap-1.5 p-2.5 rounded">
                <img src={tableIcon} alt="icon" />
                <p className="text-[18px] font-medium">Transaction Patterns</p>
            </div>
            <div className="grid grid-cols-3 gap-x-6 lg:grid-cols-2">
                {
                    [
                        {
                            title: "Maximum Balance Week",
                            value: `Week ${data?.max_bal_week_list_dict?.[0] || 'not available'}`,
                            icon: highestBalance,
                        },
                        {
                            title: "Maximum Deposit Week",
                            value: `Week ${data?.max_depo_week_list_dict?.[0] || 'not available'}`,
                            icon: lastDeposit,
                        },
                        {
                            title: "Minimum Balance Week",
                            value: `Week ${data?.min_bal_week_list_dict?.[0] || 'not available'}`,
                            icon: highestBalance,
                        },
                        {
                            title: "Minimum Balance Day",
                            value: getDateFromDayinYear(getYear, data?.min_bal_days_list_dict?.[0] || 0),
                            icon: highestBalance,
                        },
                        {
                            title: "Maximum Balance Day",
                            value: getDateFromDayinYear(getYear, data?.max_bal_day_list_dict?.[0] || 0),
                            icon: highestBalance,
                        },
                        {
                            title: "Maximum Deposit Day",
                            value: getDateFromDayinYear(getYear, data?.max_depo_day_list_dict?.[0] || 0),
                            icon: lastDeposit,
                        },
                        {
                            title: "Week With Lowest Balance",
                            value: `Week ${data?.min_bal_week_list_dict?.[0] || 'not available'}`,
                            icon: highestBalance,
                        },
                        {
                            title: "Number Of Negative Balance (Days)",
                            value: data?.negBalDays || 0,
                            icon: highestBalance,
                        }, 
                    ].map((item, idx)=>(
                        <div key={idx}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top">
                                    <p>{item.title}</p>
                                    <img src={item.icon} alt="icon" className="credit_boxes-single-patternIcon" />
                                </div>
                                <p className="credit_boxes-single-patternText">{item.value}</p>
                                {item?.slot}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}