import Table from "components/Table";
import tableIcon from "images/svg/loan-performance.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";
import creditChek from "images/svg/apps/creditChek.svg";
import { IncomeService } from "services/income.service";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import clsx from "clsx";


export default function RecentActivities () {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([])
    const ngBanks = useSelector(state => state?.banksData?.banks)

    useEffect(()=>{
        setIsLoading(true)
        let payload = JSON.stringify({
            borrowerId: JSON.parse(sessionStorage.getItem('incomeBorrower')).data
        });
        IncomeService.monoTransactions(payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if (res.status === "success") {
                    setData(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="-mt-6">
            <Table
                titleIcon={tableIcon}
                tableTitle={"Recent Activities"}
                data={data}
                isLoading={isLoading}
                hideSearch={false}
                trClassName={"relative"}
                theadClassName="bg-[#EAECF0]"
                searchPlaceHolder={"Search by date..."}
                rows={[
                    {
                        header:'Type',
                        view: (item) => (
                            <p 
                                className={clsx(
                                    "py-[10px] pl-[10px] pr-[5px] font-medium",
                                    item?.type?.toLowerCase()==="debit" ? "text-[#D92D20]":"text-[#12B76A]"
                                )}
                            >
                                {item?.type || "Not Available"}
                            </p>
                        ),
                    },
                    {
                        header:'Narration',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.narration?.slice(0,30) ?? "---"}
                            </p>
                        ),
                    },
                    {
                        header:'Amount',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currencyFormatter(item.amount/100, 2, "NGN")}
                            </p>
                        ),
                    },
                    {
                        header:'Date',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {dayjs(item.date).format("DD/MM/YYYY hh:mmA")}
                            </p>
                        ),
                    },
                    {
                        header:'Bank',
                        view: (item) => (
                            <div className="py-2.5">
                                <img src={ngBanks.find(o => o.code === item.bankCode)?.logo?.url ?? creditChek} alt="banklogo" width="40px" />
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}