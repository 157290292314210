import { IdentityService } from "services/identity.service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { RiErrorWarningLine } from "react-icons/ri";
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import whatsappIcon from "../../images/svg/whatsapp.svg";
import loader from "../../images/gif/white-loader.gif";
import clsx from "clsx";
import { handleRequestErrors } from "utils/helpers";
import { IncomeService } from "services/income.service";
import dayjs from "dayjs";
import hourglass from "images/gif/hourglass.gif"
import failed from "images/svg/income/acc_error.svg"


function NewShare ({show, cancel, doAction, borrowerId, type}) {
    const [email, setEmail] = useState("");
    const [disableAction, setDisbleAction] = useState(false);
    const [formLength, setFormLength] = useState("short");
    const activeApp = useSelector(state => state?.app?.active)
    const [seeBanks, setSeeBanks] = useState(false);
    const [encryptLoading, setEncryptLoading] = useState(false)
    const [encryptFailed, setEncryptFailed] = useState(false)
    const [magicLink, setMagicLink] = useState("")

    useEffect(()=>{
        if(!!type){
            setEncryptLoading(true)
            let payload = JSON.stringify({
                type: formLength,
                appId: activeApp.appId,
                status: activeApp.isLive,
                appLink: activeApp.appLink,
                app_id: activeApp._id,
                borrowerId: borrowerId,
                expires: dayjs().add(12, "hour")
            })
            
            IncomeService.encryptText({text: payload})
                .then(resp => {
                    setEncryptLoading(false)
                    setMagicLink(
                        `${window.location.origin}/customer/${
                            type === "share-whatsapp"?"onboarding":"share-data"
                        }?d=${encodeURIComponent(resp)}`
                    )
                })
                .catch(()=>{
                    setEncryptFailed(true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, borrowerId, formLength])

    const submit = () => {
        setDisbleAction(true)
        if(email===""){
            toast.error("Please input an email address");
            setDisbleAction(false)
        } else {
            let payload = JSON.stringify({
                email: email,
                link: magicLink ,
                type: type === "share-whatsapp" ? "kyc":"bank"
            })
            IdentityService.shareLinkToMail(payload)
                .then((response) => response.data)
                .then((res) => {
                    setDisbleAction(false)
                    if (res.status===true) {
                        toast.success(`Message sent successfully to ${email}`);
                        doAction();
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setDisbleAction(false)
                    handleRequestErrors(err)
                });
        }
    }

    const doCopy = () => {
        const copied = magicLink
        navigator.clipboard.writeText(copied);
        const ttc = document.getElementById("textToCopy");
        ttc.innerText='COPIED!';
        setTimeout(function () {
            ttc.innerText=copied;
        }, 1000)
    }

    const close = () => {
        cancel()
        setEncryptFailed(false)
    }

    return(
        <Modal show={show} size="md" onHide={close} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="bg-white p-6 rounded-[12px] w-full sm_min:max-w-[400px] z-[3] sm_min:absolute sm_min:top-[40%] sm_min:left-[50%] sm_min:-translate-y-1/2 sm_min:-translate-x-1/2 sm:w-full sm:box-border xs:p-3">
                <div className="grid grid-cols-2 cursor-pointer shadow-sm">
                    <div 
                        className={clsx(
                            "flex items-center justify-center gap-x-4 py-2.5 xs:text-xs xs:gap-x-1.5",
                            true ? "rounded-l-lg text-white bg-cc-primary":"bg-white"
                        )}
                    >
                        <EnvelopeIcon color="#FCFCFD" />
                        <p className="text-base font-medium">Via E-mail</p>
                    </div>
                    <div 
                        className={clsx(
                            "flex items-center justify-center gap-x-4 relative xs:text-xs xs:gap-x-1.5",
                            false ? "rounded-tr-lg text-white bg-cc-primary":"bg-white"
                        )}
                    >
                        <img src={whatsappIcon} width="20px" alt="share"/>
                        <p className="text-base font-medium">Via Whatsapp</p>
                        <span className="absolute text-[#98A2B3] text-[10px] bottom-0 left-[70px]">Coming soon</span>
                    </div>
                </div>
                <div className="pt-8 box-border mb-7 relative">
                    {
                        encryptLoading &&
                        <div className="absolute w-full h-full z-[100] bg-white flex flex-col items-center justify-center">
                            <img 
                                src={encryptFailed ? failed:hourglass} 
                                alt="loading" 
                                className={clsx(
                                    encryptFailed && "w-[60px]"
                                )}
                            />
                            <p className="font-bold text-2xl mt-2.5 text-center">
                                {
                                    encryptFailed ?
                                    "Creating magic link failed, please contact support!"
                                    :
                                    "Creating magic link..."
                                }
                            </p>
                        </div>
                    }
                    <p className="text-[20px] text-[#101828] font-medium mb-1 sm:text-base">Send or share link via e-mail</p>
                    <div className="text-[#D92D20] text-xs flex items-center gap-x-1 mb-[16px]">
                        <RiErrorWarningLine className="shrink-0 scale-[1.3]" />
                        <p>Please note: Bank statement assessment for income insight are currently available exclusively in Naira (NGN) currency.</p>
                    </div>
                    <div>
                        <p className="text-sm text-cc-black-3 font-medium mb-1">Customer E-mail</p>
                        <div className="flex gap-x-2.5">
                            <input type="text" className="w-full border-[1px] border-[#E5EEFF] bg-[#E5EEFF] py-2.5 px-3.5 rounded" placeholder="Enter customers email address" onChange={(e)=>setEmail(e.target.value)}/>
                            <button className="bg-cc-primary border-[1px] border-cc-primary rounded-[6px] text-white font-medium w-[100px]" onClick={submit} disabled={disableAction}>
                                {disableAction ? <img src={loader} width="25px" alt="" className="block mx-auto" /> : "Send" }
                            </button>
                        </div>
                        <p className="text-sm text-cc-black-3 font-medium mt-7 mb-1">Share link</p>
                        <div className="flex items-center gap-x-2.5 my-2">
                            <div className="overflow-hidden text-sm text-[#98A2B3] font-medium">
                                <p id="textToCopy" className="whitespace-nowrap">
                                    {magicLink}
                                </p>
                            </div>
                            <div className="text-cc-primary text-base font-medium flex gap-2">
                                <p onClick={()=>doCopy()}>Copy</p>
                                <a 
                                    target="_blank" 
                                    rel="noreferrer"  
                                    href={magicLink}
                                >
                                    <p className="underline text-cc-primary">Open</p>
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-between gap-x-2.5 text-sm text-[#98A2B3] my-6">
                            <p>See all Bank Accounts linked to the consented BVN</p>
                            <Toggle
                                defaultChecked={seeBanks}
                                icons={false}
                                onChange={()=> setSeeBanks(!seeBanks)} 
                            />
                        </div>
                        <div className="flex items-center justify-between gap-x-2.5 text-sm text-[#98A2B3]">
                            <p>Collect guarantor consent and loan information</p>
                            <Toggle
                                defaultChecked={formLength==="short"?false:true}
                                icons={false}
                                onChange={()=> formLength==="short"?setFormLength("long"):setFormLength("short")} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const EnvelopeIcon = ({color}) => {
    return(
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 2.20431V15.168L15.165 8.84553L14.2462 9.61477L19.1283 16H0.871708L5.75378 9.61477L4.83501 8.84553L0 15.168V2.2043L9.99942 11.2763L20 2.20431ZM19.9993 0V0.573533L9.99989 9.64553L0.00046875 0.573544V0H19.9993Z" fill={color}/>
        </svg>
    )
}

export default NewShare;