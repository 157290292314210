/* eslint-disable use-isnan */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {Row, Col} from "react-bootstrap";
import CreditCard from "./components/VirtualCard/CreditCard";
import { IncomeService } from "services/income.service";
import Back from 'components/shared/back'
import CashFlowHighlight from "./components/CashFlowHighlights";

import fico from "../../../images/svg/credit/fico.svg";
import red from "../../../images/svg/income/red-income.svg";
import blue from "../../../images/svg/income/blue-income.svg";
import green from "../../../images/svg/income/green-income.svg";
import scrollLeft from "../../../images/svg/card/scrollLeft.svg";
import scrollRight from "../../../images/svg/card/scrollRight.svg";
import download from "../../../images/svg/credit/download.svg";
import outstanding from "../../../images/svg/income/outstanding.svg";
import overdue from "../../../images/svg/income/overdue.svg";
import totalValue from "../../../images/svg/income/total-value.svg";
import { TransactionPatterns } from "./components/TransactionPatterns";
import { useSelector } from "react-redux";
import Name from "components/shared/Name";
import { currencyFormatter, generatePDF, handleRequestErrors } from "utils/helpers";
import FilePreview from "components/shared/FilePreview";
import { useBilledPageExit } from "hooks/feedback.hook";
import MonthlySittingBalance from "./components/MonthlySittingBalance";
import RecurrentExpenses from "./components/RecurrentExpenses";
import SpendingCategories from "./components/SpendingCategories";
import RecentActivities from "./components/RecentActivities";
import PDFView from "components/PDFView";
import { toast } from "react-toastify";
import clsx from "clsx";
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { FaChevronDown } from "react-icons/fa6";
import OutsideClickHandler from "react-outside-click-handler";


function InsightDetails ({setDetailName}) {
    const navigate = useNavigate();

    useBilledPageExit("Income Insight")

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div className="box-border">
                <Back to={()=> navigate(`/income/link-account?tm=${new Date().toISOString()}`)} />
                <PageContent setDetailName={setDetailName} />
                <PDFView id={"income_insight_report"}>
                    <div className="bg-white py-10 px-6 min-h-[295mm]">
                        <PageContent setDetailName={setDetailName} isPdf />
                    </div>
                </PDFView>
            </div>
        </div>
    )
}

const PageContent = ({setDetailName, isPdf}) => {
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const [creditData, setCreditData] = useState({
        tableFor: "accounts",
        headings: ["CUSTOMER NAME", "ACCOUNT NUMBER", "BALANCE", "DATE", "BANK"],
        items: []
    })
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const [isDownloading, setIsDownloading] = useState(false)
    const [selectedEdti, setSelectedEdti] = useState(33)
    const [recommendedLoanMonths, setRecommendedLoanMonths] = useState("12")
    const [showSelectedEdti, setShowSelectedEdti] = useState(false)
    const [showRecommendedLoanMonths, setShowRecommendedLoanMonths] = useState(false)


    let income = {};

    try {
        let details2 = sessionStorage?.getItem('incomeInsightData');
        income = JSON?.parse(details2);
    } catch (err) {
        //err
    }

    useEffect(()=>{
        IncomeService.encryptText(JSON.stringify({text: businessId})).then(resp => {
            let business_id = resp
            let borrowerId = JSON.parse(sessionStorage.getItem('incomeBorrower')).data
            let payload = {business_id, borrowerId  }
            IncomeService.getUserAcccounts(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === "success" ) {
                        setCreditData({
                            ...creditData,
                            items: res.data
                        })
                        setDetailName(`${res?.data[0]?.name}`)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [cards] = useState(["#5252FF"]);
    const ref = useRef(null);
    const [showLeftScrollAction, setShowLeftScrollAction] = useState(false);
    const [showRightScrollAction, setShowRightScrollAction] = useState(true);
    const [refScrollLeft, setRefScrollLeft] = useState(0);

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
        setRefScrollLeft((ref.current.scrollLeft += scrollOffset));
    };

    useEffect(() => {
        // for the left scroll action
        if (refScrollLeft < 1) {
        setShowLeftScrollAction(false);
        } else {
        setShowLeftScrollAction(true);
        }
        // for the left scroll action
        if (refScrollLeft >= ref.current.scrollWidth - ref.current.clientWidth) {
        setShowRightScrollAction(false);
        } else {
        setShowRightScrollAction(true);
        }
    }, [refScrollLeft]);

    return(
        <div>
            <div style={{display:'flex'}}>
                <Name data={creditData?.items[0]?.name||"User"} />
                {
                    !isPdf &&
                    <div className="ml-auto">
                        <button 
                            className={clsx(
                                "flex items-center gap-1.5 border-[1px] border-[#0046E6] box-border rounded-[7px] py-[5px] px-[15px] text-[1em] bg-white",
                                isDownloading ? "cursor-wait":"cursor-pointer"
                            )}
                            onClick={()=>{
                                if(!isDownloading){
                                    setIsDownloading(true)
                                    generatePDF(
                                        "income_insight_report", 
                                        "income_insight_report",
                                        ()=>{
                                            setIsDownloading(false)
                                            toast.success("Report downloaded successfully!")
                                        }
                                    )
                                }
                            }}
                        > 
                            <img src={download} alt="w-[20px]" />
                            <p>Download Report</p>
                        </button>
                    </div>
                }
            </div>
            <div className="my-[50px]">
                <h5 className="text-[1.2em] font-bold capitalize mb-[15px]">Connected accounts</h5>
                <div className="relative">
                    {showLeftScrollAction && (
                        <div className="cards-left-arrow" onClick={() => scroll(-321)}>
                            <img src={scrollLeft} alt="" />
                        </div>
                    )}
                    {showRightScrollAction && (
                        <div className="cards-right-arrow" onClick={() => scroll(321)}>
                            <img src={scrollRight} alt="" />
                        </div>
                    )}
                    <div className="card-scroll" ref={ref}>
                        {cards.map((item) => (
                            <CreditCard
                                ngBanks={ngBanks}
                                creditData={creditData}
                                color={item}
                                key={item._id}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex items-center mb-[15px] gap-2">
                <h5 className="text-[1.2em] font-bold capitalize">Income Insights for {creditData?.items[0]?.name?.toLowerCase()}</h5>
                <div className="flex items-center gap-1.5 bg-[#F4F3FF] rounded-[10px] w-[100px] justify-center">
                    <div className="w-2.5 h-2.5 rounded-full bg-[#5925DC]" />
                    <p className="text-[1em] font-medium text-[#5925DC]">
                        {income?.edti?.number_of_active_months} {income?.edti?.number_of_active_months===1?'month':'months'}
                    </p>
                </div>
            </div>
            <div className="box-border grid grid-cols-3 gap-7 lg:grid-cols-2">
                {
                    [
                        {
                            icon: fico,
                            title: "Debt Burden Ratio",
                            value: (
                                <div className="flex items-center gap-[10px] relative">
                                    <p>{Math.round(selectedEdti)}%</p>
                                    <FaChevronDown 
                                        className="w-[16px] cursor-pointer" 
                                        onClick={()=>{
                                            setShowSelectedEdti(!showSelectedEdti)
                                        }} 
                                    />
                                    {
                                        showSelectedEdti && 
                                        <div className="absolute top-10 bg-white z-[9] rounded-lg overflow-hidden">
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    setShowSelectedEdti(false)
                                                }}
                                            >
                                            <div className="h-[150px] overflow-y-scroll">
                                                {
                                                    ["15", "20", "25", "30", "33", "35", "40", "45", "50", "55", "60", "65", "70", "75"].map(item => (
                                                        <div 
                                                            className="w-full text-xs font-bold cursor-pointer py-2.5 px-10 bg-white shadow-[3px_4px_18px_0px_#0000001A]"
                                                            onClick={()=>{
                                                                setSelectedEdti(item)
                                                                setShowSelectedEdti(false)
                                                            }}
                                                        >{item}%</div>
                                                    ))
                                                }
                                            </div>
                                            </OutsideClickHandler>
                                        </div>
                                    }
                                </div>
                            ),
                            info: "You can choose your respective DBR of choice from the drop down below, and we will automatically calculate the Recommended Loan Amount for you"
                        },
                        {
                            icon: green,
                            title: "Average Monthly Inflow",
                            value: currencyFormatter(
                                    (income?.edti?.averageMonthlyIncome||0)/100, 
                                        // income?.edti?.average_monthly_income||0)/100, 
                                    2, "NGN"
                                ),
                        },
                        {
                            icon: green,
                            title: "Total Money Received",
                            value: currencyFormatter(
                                    (income?.edti?.totalMoneyReceive || income?.edti?.totalMoneyReceived || 0)/100, 
                                    2, "NGN"
                                ),
                        },
                        {
                            icon: red,
                            title: "Total Money Spent",
                            value: currencyFormatter((income?.edti?.totalMoneySpent||0)/100, 2, "NGN"),
                        },
                        {
                            icon: red,
                            title: "Average Monthly Recurring Expense",
                            value: currencyFormatter(income?.edti?.average_recurring_debt/100, 2, "NGN"),
                        },
                        {
                            icon: blue,
                            title: "Recommended Loan Amount",
                            value: (
                                <div className="flex items-center justify-between gap-[10px] relative">
                                    <p>
                                        {
                                            currencyFormatter(
                                                (
                                                    income?.edti?.eligibleAmount?.[`${selectedEdti}%`]?.[`${recommendedLoanMonths}months`]?.total_amount
                                                )/100, 
                                                2, 
                                                "NGN"
                                            )
                                        }
                                    </p>
                                    <div 
                                        className="rounded-lg text-xs cursor-pointer flex gap-1 items-center font-bold py-2.5 px-[18px] shadow-[3px_4px_18px_0px_#0000001A]"
                                        onClick={()=>{
                                            setShowRecommendedLoanMonths(true)
                                        }} 
                                    >
                                        <span>{recommendedLoanMonths} months</span>
                                        <FaChevronDown className="w-[10px]" />
                                    </div>
                                    {
                                        showRecommendedLoanMonths && 
                                        <div className="absolute top-10 right-0 bg-white z-[9]">
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    setShowRecommendedLoanMonths(false)
                                                }}
                                            >
                                                <div className="rounded-lg overflow-hidden">
                                                    {
                                                        ["12", "6", "3"].map(item => (
                                                            <div 
                                                                className={clsx(
                                                                    "w-full text-xs font-bold cursor-pointer py-2.5 px-[20px] bg-white shadow-[3px_4px_18px_0px_#0000001A]",
                                                                    recommendedLoanMonths===item && "hidden"
                                                                )}
                                                                onClick={()=>{
                                                                    setRecommendedLoanMonths(item)
                                                                    setShowRecommendedLoanMonths(false)
                                                                }}
                                                            >{item} months</div>
                                                        ))
                                                    }
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                    }
                                </div>
                            ),
                            info: "This Recommended Loan Amount is based on the preferable DBR of choice you have chosen"
                        },
                        {
                            icon: totalValue,
                            title: "Total Loan Value",
                            value: currencyFormatter(income?.edti?.totalBorrowed, 2, "NGN"),
                            hide: !income?.edti?.creditData,
                        },
                        {
                            icon: outstanding,
                            title: "Total Oustanding Value",
                            value: currencyFormatter(income?.edti?.totalOutstanding, 2, "NGN"),
                            hide: !income?.edti?.creditData,
                        },
                        {
                            icon: overdue,
                            title: "Total Overdue",
                            value: currencyFormatter(income?.edti?.totalOverdue, 2, "NGN"),
                            hide: !income?.edti?.creditData,
                        },
                    ].map((item, idx)=>(
                        <div
                            key={idx}
                            className={clsx(
                                "bg-white border-[0.15px] border-[#4C8BF5] box-border rounded-[15px] p-[15px] shadow-[0px_4px_90px_rgba(163,171,185,0.24)]",
                                item.hide && "hidden",
                            )}
                        >
                            <div className="flex gap-2.5 items-center mb-2.5">
                                <img src={item.icon} alt="icon" className="w-[30px]" />
                                <p className="text-[1em] font-bold opacity-50">{item.title}</p>
                                {
                                    !!item.info &&
                                    <IoMdInformationCircle 
                                        data-tooltip-id={item.title}
                                        className="text-[#DE213C] ml-auto w-[24px] scale-[1.2]" 
                                    />
                                }
                                <Tooltip 
                                    id={item.title} 
                                    place="right"
                                    render={()=>(
                                        <div className="text-xs w-[200px]">
                                            {item.info}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="text-[1.3em] font-mulish">{item.value}</div>
                        </div>
                    ))
                }
            </div>
            <div className="my-6">
                <h5 className="text-[1.2em] font-bold capitalize">Bank Statements</h5>
                <div className="mb-10">
                    <Row>
                        {
                            creditData?.items?.map(item=>(
                                <Col md={6} lg={4} key={item._id}>
                                    <FilePreview
                                        name={`${item?.name || "user"}.pdf`}
                                        additionalName={item?.institution?.name||"---"}
                                        hideDownload={item.source!=="pdf"}
                                        downloadLink={item?.pdfUrl}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
            <div className="flex flex-col gap-6 pt-[20px]">
                <CashFlowHighlight data={income?.edti?.creditBreakdown} disposableIncome={income?.edti?.disposableIncome}/>
                <TransactionPatterns data={income?.edti?.creditBreakdown} forYear={income?.edti?.EDTI} />
                <MonthlySittingBalance />
                <div className="mt-6">
                    <RecurrentExpenses /> 
                </div> 
                <SpendingCategories name={creditData?.items[0]?.name ?? ""} />
                <RecentActivities />
            </div>
        </div>
    )
}

export default InsightDetails;